import React from "react";
import Pill from "../Pill";
import FAIcon from "../FAIcon";

const StreamDuration = ({ duration }) => {
  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hrs).padStart(2, "0")}:${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  return (
    <Pill bg="translucent" color="white">
      <FAIcon type="clock" variant="regular" color="white" mr={1} />
      {formatTime(duration)}
    </Pill>
  );
};

export default StreamDuration;
