import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Loading from "@/components/Loading";
import { observer } from "mobx-react-lite";
import FAIcon from "@/components/FAIcon";
import { uiStore } from "@/libs/stores/ui.store";
import { useEffect } from "react";
import { useIsCreator } from "@/libs/hooks/useIsCreator";

const MenuItem = ({ to, icon, label, isActive }) => (
  <Link to={to} style={{ flex: 1 }}>
    <Flex
      direction="column"
      align="center"
      py={2}
      color={isActive ? "white" : "whiteAlpha.800"}
    >
      <FAIcon type={icon} fontSize="xl" mb={1} />
      <Text fontSize="xs">{label}</Text>
    </Flex>
  </Link>
);

const CreatorLayout = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading: isCreatorLoading, data: creatorData } = useIsCreator();

  useEffect(() => {
    const checkAccess = async () => {
      console.log("Creator layout access check:", {
        isCreatorLoading,
        creatorData,
      });

      if (!isCreatorLoading && !creatorData?.isCreator) {
        console.log("Not a creator, redirecting");
        navigate("/");
      }
    };

    checkAccess();
  }, [isCreatorLoading, creatorData, navigate]);

  if (isCreatorLoading) {
    return (
      <Box minH="100vh" bg="gray.900">
        <Loading />
      </Box>
    );
  }

  const menuItems = [
    { path: "/creator", icon: "user", label: "個人檔案" },
    { path: "/creator/chats", icon: "comments", label: "聊天" },
    { path: "/creator/earnings", icon: "dollar-sign", label: "收益" },
  ];

  return (
    <Box minH="100vh" bg="gray.900">
      {uiStore.showHeaderNav && (
        <>
          <Box
            position="fixed"
            bottom={0}
            left={0}
            right={0}
            bg="gray.900"
            borderTop="1px"
            borderColor="whiteAlpha.200"
          >
            <HStack spacing={0}>
              {menuItems.map(({ path, icon, label }) => (
                <MenuItem
                  key={path}
                  to={path}
                  icon={icon}
                  label={label}
                  isActive={location.pathname === path}
                />
              ))}
            </HStack>
          </Box>
        </>
      )}

      <Box flex="1" pb={uiStore.showHeaderNav ? 20 : 0}>
        <Outlet />
      </Box>
    </Box>
  );
});

export default CreatorLayout;
