import {
  Box,
  CircularProgress,
  Flex,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import getPublicUrl from "@/libs/get-public-url";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import AudioPlayer from "./Audio";
import Bubble from "./Bubble";
import Media from "./Media";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { chatroomStore } from "@/libs/stores/chatroom.store";

const Message = (props) => {
  const { type, text, audio, status, gift, isFromUser, buffered, createdAt } =
    props;
  const { t } = useTranslation();

  const parsedText = useMemo(() => {
    if (!text) return null;
    const urlRegex = /(@[A-Za-z0-9_.]+)/g;
    const parts = text.split(urlRegex);

    return parts.map((part, index) =>
      urlRegex.test(part) ? (
        <Box
          key={index}
          as={Link}
          to={`/c/${part}`}
          textDecor="underline"
          color="blue.400"
          fontWeight="bold"
        >
          {part}
        </Box>
      ) : (
        part
      ),
    );
  }, [text]);

  const child = useMemo(() => {
    switch (type) {
      case "audio":
        return (
          <Bubble isFromUser={isFromUser} whiteSpace="nowrap">
            <AudioPlayer src={getPublicUrl(audio)} />
          </Bubble>
        );
      case "gift":
        return (
          <Box>
            <Image
              src={getPublicUrl(
                chatroomStore.gifts.find((g) => g.id === gift)?.path ||
                  `/assets/gifts/${gift}.png`,
              )}
              loading="lazy"
              width={76}
              loop={0}
            />
          </Box>
        );
      case "image":
      case "video": {
        const media = <Media {...props} isFromUser={isFromUser} />;
        if (type === "video" && status !== "released")
          return (
            <Bubble isFromUser={isFromUser}>
              <VStack align="center" gap={3} py={6}>
                <CircularProgress color="secondary.500" isIndeterminate />
                <Text>{t("chats.messages.video_processing")}</Text>
              </VStack>
            </Bubble>
          );
        return text ? (
          <Bubble isFromUser={isFromUser} px={0} pt={0}>
            {media}
            <Text px={3} pt={2}>
              {text}
            </Text>
          </Bubble>
        ) : (
          media
        );
      }
      case "text":
      default:
        return <Bubble isFromUser={isFromUser}>{parsedText}</Bubble>;
    }
  }, [type, isFromUser, audio, gift, parsedText, props, status, t, text]);

  return (
    <Flex
      flexDirection={isFromUser ? "row" : "row-reverse"}
      justify="flex-end"
      alignItems="flex-end"
      width="100%"
      opacity={buffered ? 0.6 : 1}
    >
      <Text color="white" px={2} wordBreak="keep-all">
        {buffered
          ? t("chats.sending_message")
          : dayjs(+createdAt).format("HH:mm")}
      </Text>
      {child}
    </Flex>
  );
};

export default Message;
