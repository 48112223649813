import { Video } from "@/libs/models/video";
import { makeAutoObservable, runInAction } from "mobx";
// @ts-ignore
import shaka from "shaka-player";

// import shaka from "shaka-player/dist/shaka-player.compiled.debug";

export class PlayerStore {
  active = false;
  globalMuted = true;
  player = new shaka.Player();
  videoRef: HTMLVideoElement = document.createElement("video");
  videos: NightCoVideo[] = [];
  isPlaying = false;
  currentIndex = 0;
  isBuffering = false;

  showThumbnail = true;

  constructor() {
    shaka.polyfill.installAll();

    this.player.configure({
      streaming: {
        bufferingGoal: 0.5,
      },
    });
    makeAutoObservable(this);
    this.init();
  }

  get currentActivePlayer() {
    return this.videos.find((v) => v.active);
  }

  init() {
    console.log("Initializing player");
    const v = this.videoRef;
    v.playsInline = true;
    v.style.position = "absolute";
    v.style.top = "50%";
    v.style.left = "50%";
    v.style.transform = "translate(-50%, -50%)";
    v.style.width = "100%";
    v.style.height = "100%";
    v.style.objectFit = "cover";
    v.autoplay = true;
    v.loop = true;
    v.muted = true;
    v.onclick = () => {
      console.log("Video clicked");
      v.paused ? v.play() : v.pause();
    };
    v.addEventListener("canplay", () => {
      this.videoRef
        .play()
        .catch((e) => console.warn("Failed to play video:", e))
        .then(() => {});
    });

    v.addEventListener("loadstart", () => {
      runInAction(() => {
        this.isBuffering = true;
      });
    });

    v.addEventListener("play", () => {
      runInAction(() => {
        this.isBuffering = false;
        this.isPlaying = true;
      });
    });

    v.addEventListener("pause", () => {
      runInAction(() => {
        this.isPlaying = false;
      });
    });
    this.player.attach(v);
  }

  getActivePlayer() {
    return this.videos[this.currentIndex];
  }

  toggleGlobalMute() {
    runInAction(() => {
      this.globalMuted = !this.globalMuted;
      this.videoRef.muted = this.globalMuted;
      console.log("Global mute", this.globalMuted);
    });
  }

  close() {
    console.log("Closing player");
    runInAction(() => {
      this.active = false;
      this.isBuffering = true;
      this.isPlaying = false;
      this.videoRef.pause();
      this.player.unload();
    });
  }

  clear() {
    console.log("Clearing player");
    runInAction(() => {
      this.close();
      this.videos = [];
    });
  }

  openPlayList(index = 0) {
    runInAction(() => {
      this.active = true;
      this.isBuffering = true;
      this.currentIndex = index;
      this.play(this.videos[index]?.id, true).catch((e) =>
        console.warn("Failed to play video:", e),
      );
    });
  }

  inQueue(video: Video) {
    runInAction(() => {
      if (!this.videos.some((v) => v.id === video.id)) {
        this.videos.push(new NightCoVideo(video, this));
        if (this.videos.length === 1) {
          this.videos[0].preload();
        }
      }
    });
  }

  //把影片放到index = 0的位置
  unshiftQueue(video: Video) {
    runInAction(() => {
      if (!this.videos.some((v) => v.id === video.id)) {
        this.videos.unshift(new NightCoVideo(video, this));
        if (this.videos.length === 1) {
          this.videos[0].preload();
        }
      }
    });
  }

  setVideos(videos: Video[]) {
    runInAction(() => {
      this.videos = videos.map((v) => new NightCoVideo(v, this));
    });
  }

  getVideo(id: string) {
    return this.videos.find((v) => v.id === id);
  }

  async play(videoId = this.videos[this.currentIndex]?.id, first = false) {
    const oldIndex = this.currentIndex;
    const newIndex = this.videos.findIndex((v) => v.id === videoId);
    if (newIndex === -1) {
      console.warn(`Video ${videoId} not found in playlist`);
      return;
    }

    // console.log("Playing video", videoId, "from index", newIndex);
    // console.log(this.videos.map((v) => v.id));

    if (newIndex === oldIndex && this.isPlaying && !first) {
      // console.log("Video already playing");
      return;
    }
    this.isPlaying = true;
    // console.log("Playing video", videoId);

    runInAction(() => {
      this.currentIndex = newIndex;
    });
    try {
      if (oldIndex !== newIndex) {
        const prev = this.videos[oldIndex];
        prev.preloadManager = await this.player.unloadAndSavePreload();
      }
      // this.videoRef.poster = this.videos[this.currentIndex].video.thumbnails;
      if (this.currentActivePlayer?.id === videoId) {
        return;
      }
      await this.videos[this.currentIndex].load();
      await this.handlePreload();
      void this.removeOutOfBoundPreloadManager();
      if (!this.active) {
        this.unloadAndSavePreload();
        this.videoRef.pause();
      }
    } catch (e) {
      console.warn("Error during video playback:", e);
    }
  }

  unloadAndSavePreload() {
    this.getActivePlayer().preloadManager = this.player.unloadAndSavePreload();
  }

  removeOutOfBoundPreloadManager() {
    const tasks: any[] = [];
    this.videos.forEach((v) => {
      if (this.shouldClearPreload(v.id)) tasks.push(v.clearCache());
    });
    return Promise.all(tasks);
  }

  shouldPreload(id: string) {
    const idx = this.videos.findIndex(
      (v) => v.id === id && v.id !== this.currentActivePlayer?.id,
    );
    return idx == this.currentIndex + 1;
  }

  shouldClearPreload(id: string) {
    if (this.currentIndex < 3) return false;
    const idx = this.videos.findIndex((v) => v.id === id);
    return idx > this.currentIndex + 2 || idx < this.currentIndex - 2;
  }

  async handlePreload() {
    const tasks = this.videos
      .filter((v) => this.shouldPreload(v.id))
      .map((v) => v.preload());
    await Promise.all(tasks);
  }
}

export const playerStore = new PlayerStore();

export class NightCoVideo {
  id: string;
  store: PlayerStore;
  playbacks: any[] = [];
  active = false;
  video: Video;
  videoRef: HTMLVideoElement;
  shakaPlayer: shaka.Player;
  preloadManager:
    | shaka.media.PreloadManager
    | Promise<shaka.media.PreloadManager>
    | null = null;

  constructor(video: Video, store = playerStore) {
    makeAutoObservable(this);
    this.video = video;
    this.id = video.id;
    this.store = store;
    this.videoRef = store.videoRef;
    this.shakaPlayer = store.player;
  }

  async preload() {
    try {
      if (this.preloadManager instanceof Promise) {
        // console.log("Preload already in progress");
        return await this.preloadManager;
      }
      if (this.preloadManager) return this.preloadManager;

      // console.log("Preloading video", this.id);
      this.preloadManager = await this.shakaPlayer.preload(
        this.video.playbacks[0].streamUrl,
      );
      // console.log("Preload successful for video", this.id);

      return this.preloadManager;
    } catch (e) {
      console.warn(`Preload failed for video ${this.id}:`, e);
      this.preloadManager = null;
      return null;
    }
  }

  async load() {
    await runInAction(async () => {
      try {
        // count time to load video
        // const startTime = new Date().getTime();
        // console.log("load: Loading video", this.id);

        if (this.preloadManager) {
          console.log("load: Using preload manager");
          const manager =
            this.preloadManager instanceof Promise
              ? await this.preloadManager
              : this.preloadManager;
          if (manager) {
            console.log("load: Preload successful");
            await this.shakaPlayer.load(manager);
            // console.log(
            //   "load: Video loaded in",
            //   new Date().getTime() - startTime,
            //   "ms",
            // );
            // const status = await this.shakaPlayer.getStats();
            // console.log(status);
            return;
          }
        }
        console.log("load: Preload manager not available");
        await this.shakaPlayer.load(this.video.playbacks[0].streamUrl);
        // console.log(
        //   "load: Video loaded in",
        //   new Date().getTime() - startTime,
        //   "ms",
        // );
        // const status = await this.shakaPlayer.getStats();
        // console.log(status);
      } catch (e) {
        console.warn("Error during video playback:", e);
        await this.shakaPlayer.load(this.video.playbacks[0].streamUrl);
      }
    });
  }

  async clearCache() {
    return runInAction(async () => {
      try {
        if (this.preloadManager) {
          // console.log(
          //   "Clearing cache for video",
          //   this.id,
          //   playerStore.videos.findIndex((v) => v.id === this.id),
          // );
          if (!(this.preloadManager instanceof Promise)) {
            await this.preloadManager.destroy();
          }
          this.preloadManager = null;
        }
      } catch (e) {
        console.warn("Error during cache cleanup:", e);
      }
    });
  }
}
