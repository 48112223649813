import {
  Box,
  Button,
  Flex,
  Input,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Alert,
  AlertIcon,
  Skeleton,
  Divider,
  StatHelpText,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useFirebase } from "@/libs/hooks/firebase";
import {
  collection,
  getAggregateFromServer,
  query,
  sum,
  where,
} from "firebase/firestore";
import { useIsCreator } from "@/libs/hooks/useIsCreator";

const CreatorEarningsPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [earnings, setEarnings] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [lastUpdated, setLastUpdated] = useState(null);
  const { firestore } = useFirebase();
  const { data: creatorData } = useIsCreator();

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('zh-TW', {
      style: 'currency',
      currency: 'TWD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount);
  };

  const formatDate = (date) => {
    return new Intl.DateTimeFormat('zh-TW', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  const fetchEarnings = async (start, end) => {
    if (!creatorData?.creatorId) {
      setError("找不到創作者資訊");
      return;
    }

    setIsLoading(true);
    setError("");
    
    try {
      const earningsRef = collection(firestore, "transactions");
      const q = query(
        earningsRef,
        where("creatorId", "==", creatorData.creatorId),
        where("createdAt", ">=", start),
        where("createdAt", "<=", end),
      );

      const snapshot = await getAggregateFromServer(q, {
        totalEarnings: sum("points"),
      });

      setEarnings(snapshot.data().totalEarnings || 0);
      setLastUpdated(new Date());
    } catch (error) {
      console.error("Error fetching earnings:", error);
      setError("無法取得收益資料，請稍後再試");
      setEarnings(0);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCustomDateSearch = () => {
    if (!startDate || !endDate) {
      setError("請選擇開始和結束日期");
      return;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    if (start > end) {
      setError("開始日期不能晚於結束日期");
      return;
    }

    if (end > new Date()) {
      setError("結束日期不能晚於今天");
      return;
    }

    if (end - start > 365 * 24 * 60 * 60 * 1000) {
      setError("查詢區間不能超過一年");
      return;
    }

    fetchEarnings(start.setHours(0, 0, 0, 0), end.setHours(23, 59, 59, 999));
  };

  useEffect(() => {
    const now = new Date();
    let start, end;

    switch (selectedTab) {
      case 0: // Today
        start = new Date(now.setHours(0, 0, 0, 0));
        end = new Date(now.setHours(23, 59, 59, 999));
        break;
      case 1: // This Month
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(
          now.getFullYear(),
          now.getMonth() + 1,
          0,
          23,
          59,
          59,
          999,
        );
        break;
      default:
        return;
    }

    fetchEarnings(start.getTime(), end.getTime());
  }, [selectedTab, creatorData?.creatorId]);

  return (
    <Box p={4}>
      <Text fontSize="2xl" fontWeight="bold" mb={6} color="white">
        收益統計
      </Text>

      {error && (
        <Alert status="error" mb={4} borderRadius="md">
          <AlertIcon />
          {error}
        </Alert>
      )}

      <Tabs onChange={setSelectedTab} colorScheme="purple" variant="enclosed">
        <TabList>
          <Tab color="white" _selected={{ color: "white", bg: "purple.500" }}>本日</Tab>
          <Tab color="white" _selected={{ color: "white", bg: "purple.500" }}>本月</Tab>
          <Tab color="white" _selected={{ color: "white", bg: "purple.500" }}>自訂區間</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {isLoading ? (
              <Skeleton height="100px" />
            ) : (
              <Stat>
                <StatLabel color="white">本日收益</StatLabel>
                <StatNumber color="white" fontSize="3xl">
                  {formatCurrency(earnings)}
                </StatNumber>
                {lastUpdated && (
                  <StatHelpText color="whiteAlpha.700">
                    最後更新：{formatDate(lastUpdated)}
                  </StatHelpText>
                )}
              </Stat>
            )}
          </TabPanel>

          <TabPanel>
            {isLoading ? (
              <Skeleton height="100px" />
            ) : (
              <Stat>
                <StatLabel color="white">本月收益</StatLabel>
                <StatNumber color="white" fontSize="3xl">
                  {formatCurrency(earnings)}
                </StatNumber>
                {lastUpdated && (
                  <StatHelpText color="whiteAlpha.700">
                    最後更新：{formatDate(lastUpdated)}
                  </StatHelpText>
                )}
              </Stat>
            )}
          </TabPanel>

          <TabPanel>
            <Stack spacing={6}>
              <Flex gap={4}>
                <Input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  placeholder="開始日期"
                  bg="whiteAlpha.200"
                  color="white"
                  max={new Date().toISOString().split('T')[0]}
                />
                <Input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  placeholder="結束日期"
                  bg="whiteAlpha.200"
                  color="white"
                  max={new Date().toISOString().split('T')[0]}
                />
                <Button 
                  onClick={handleCustomDateSearch} 
                  isLoading={isLoading}
                  colorScheme="purple"
                  px={8}
                >
                  查詢
                </Button>
              </Flex>

              {isLoading ? (
                <Skeleton height="100px" />
              ) : earnings > 0 && (
                <>
                  <Divider />
                  <Stat>
                    <StatLabel color="white">區間收益</StatLabel>
                    <StatNumber color="white" fontSize="3xl">
                      {formatCurrency(earnings)}
                    </StatNumber>
                    {lastUpdated && (
                      <StatHelpText color="whiteAlpha.700">
                        最後更新：{formatDate(lastUpdated)}
                      </StatHelpText>
                    )}
                  </Stat>
                </>
              )}
            </Stack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default CreatorEarningsPage;
