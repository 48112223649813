import { Box, Button, Flex, Input, Text, VStack } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useFirebase } from "@/libs/hooks/firebase";
import {
  addDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import dayjs from "dayjs";
import UserAvatar from "@/components/UserAvatar";

const CreatorChat = ({ chatroomId, creatorId }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const { firestore } = useFirebase();
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (!chatroomId) return;

    const messagesRef = collection(firestore, "chatroom-messages");
    const q = query(
      messagesRef,
      where("chatroomId", "==", chatroomId),
      orderBy("createdAt", "desc"),
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messageData = [];
      snapshot.forEach((doc) => {
        messageData.push({ id: doc.id, ...doc.data() });
      });
      setMessages(messageData);
      scrollToBottom();
    });

    return () => unsubscribe();
  }, [chatroomId, firestore]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    try {
      const messagesRef = collection(firestore, "chatroom-messages");
      await addDoc(messagesRef, {
        chatroomId,
        text: newMessage,
        type: "text",
        createdAt: Date.now(),
        sender: {
          id: creatorId,
          type: "creator",
        },
      });
      setNewMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <Box h="full" display="flex" flexDirection="column">
      <VStack flex="1" overflowY="auto" spacing={4} p={4} align="stretch">
        {messages.map((message) => (
          <Flex
            key={message.id}
            justify={
              message.sender.type === "creator" ? "flex-end" : "flex-start"
            }
            gap={2}
          >
            {message.sender.type !== "creator" && (
              <UserAvatar userId={message.sender.id} />
            )}
            <Box
              bg={message.sender.type === "creator" ? "purple.500" : "gray.700"}
              color="white"
              px={4}
              py={2}
              borderRadius="lg"
              maxW="70%"
            >
              <Text>{message.text}</Text>
              <Text fontSize="xs" color="whiteAlpha.700" textAlign="right">
                {message.createdAt
                  ? dayjs(message.createdAt).format("HH:mm")
                  : ""}
              </Text>
            </Box>
          </Flex>
        ))}
        <div ref={messagesEndRef} />
      </VStack>

      <Box p={4} borderTop="1px" borderColor="whiteAlpha.200">
        <form onSubmit={handleSendMessage}>
          <Flex gap={2}>
            <Input
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="輸入訊息..."
              bg="whiteAlpha.100"
              color="white"
            />
            <Button type="submit" colorScheme="purple">
              發送
            </Button>
          </Flex>
        </form>
      </Box>
    </Box>
  );
};

export default CreatorChat;
