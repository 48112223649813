import { useCallback, useMemo } from "react";
import { useFirebase } from "./firebase";
import { getIdToken } from "firebase/auth";

const BACKEND_SERVER = import.meta.env.VITE_BACKEND_SERVER;

const useAPI = () => {
  const { auth } = useFirebase();

  const apiRequest = useCallback(
    async (endpoint, options, optionalAuth = false) => {
      const currentUser = auth?.currentUser;
      if (!optionalAuth && !currentUser) return null;

      const headers = {};
      const token = currentUser ? await getIdToken(currentUser) : null;

      if (token) headers["Authorization"] = `Bearer ${token}`;
      return fetch(`${BACKEND_SERVER}${endpoint}`, {
        ...options,
        headers: {
          ...headers,
          "Content-Type": "application/json"
        }
      }).then((response) => {
        const acceptedStatuses = [200, 201, 206, 207];
        if (acceptedStatuses.includes(response.status)) return response.json();
        return response.text();
      });
    },
    [auth.currentUser]
  );

  // let apiRequest to be a dependency of api object,
  // so that when apiRequest change (aka auth changed),
  // all api functions get updated
  const api = useMemo(() => ({ apiRequest }), [apiRequest]);

  api.getGuestVideos = useCallback(
    async (start) => apiRequest(`/videos/guest?start=${start}`, {}, true),
    [apiRequest]
  );

  api.checkIn = useCallback(
    async () =>
      apiRequest(`/auth/check-in`, {
        method: "POST"
      }),
    [apiRequest]
  );

  api.sendOTP = useCallback(
    async (phoneNumber) =>
      apiRequest(
        `/auth/request-login-code`,
        {
          method: "POST",
          body: JSON.stringify({ phoneNumber })
        },
        true
      ),
    [apiRequest]
  );

  api.verifyOTP = useCallback(
    async (id, code) =>
      apiRequest(
        `/auth/verify-login-code`,
        {
          method: "POST",
          body: JSON.stringify({ id, code })
        },
        true
      ),
    [apiRequest]
  );

  api.claimNewbieBooster = useCallback(
    async () =>
      apiRequest(`/auth/newbie-booster`, {
        method: "POST"
      }),
    [apiRequest]
  );

  api.setReferrer = useCallback(
    async (code) =>
      apiRequest(`/auth/referrer`, {
        method: "POST",
        body: JSON.stringify({ code })
      }),
    [apiRequest]
  );

  api.addPushToken = useCallback(
    async (token) =>
      apiRequest(`/push-tokens`, {
        method: "POST",
        body: JSON.stringify({ token })
      }),
    [apiRequest]
  );

  api.deletePushToken = useCallback(
    async (token) =>
      apiRequest(`/push-tokens/${token}`, {
        method: "DELETE"
      }),
    [apiRequest]
  );

  api.getVideos = useCallback(
    async (params = {}) =>
      apiRequest(
        `/videos?${new URLSearchParams(Object.entries(params)).toString()}`
      ),
    [apiRequest]
  );

  api.getVideo = useCallback(
    async (id) => apiRequest(`/videos/${id}`),
    [apiRequest]
  );

  api.getVideosFeed = useCallback(
    async (start) => apiRequest(`/feed/videos?start=${start}`),
    [apiRequest]
  );

  api.viewVideo = useCallback(
    async (id) => apiRequest(`/videos/${id}/view`, { method: "POST" }),
    [apiRequest]
  );

  api.searchCreators = useCallback(
    async (keyword) =>
      apiRequest("/search/creators", {
        method: "POST",
        body: JSON.stringify({ keyword })
      }),
    [apiRequest]
  );

  api.getExploreSettings = useCallback(
    async () => apiRequest("/explore"),
    [apiRequest]
  );

  api.getExploreSettingsByChunk = useCallback(
    async (id, offset = 0, limit = 5) =>
      apiRequest(`/explore/chunks/${id}?offset=${offset}&limit=${limit}`),
    [apiRequest]
  );

  api.getProfile = useCallback(
    async () => apiRequest(`/profile`),
    [apiRequest]
  );

  api.updateProfile = useCallback(
    async (payload) =>
      apiRequest(`/profile`, {
        method: "PATCH",
        body: JSON.stringify(payload)
      }),
    [apiRequest]
  );

  api.uploadPicture = useCallback(
    async (picture) =>
      apiRequest(`/picture`, {
        method: "PUT",
        body: JSON.stringify({ picture })
      }),
    [apiRequest]
  );

  api.updateAvatar = useCallback(
    async () => apiRequest(`/picture`, { method: "POST" }),
    [apiRequest]
  );

  api.getCreator = useCallback(
    async (creatorId) => {
      const cleanId = creatorId.startsWith("@") ? creatorId.slice(1) : creatorId;
      return apiRequest(`/creators/${cleanId}`, {}, true);
    },
    [apiRequest]
  );

  api.getCreators = useCallback(
    async () => {
      return apiRequest(`/creators`, {}, true);
    },
    [apiRequest]
  );

  api.getCreatorVideos = useCallback(
    async (creatorId, next) =>
      apiRequest(`/creators/${creatorId}/videos?next=${next}`, {}, true),
    [apiRequest]
  );

  api.getLikedVideos = useCallback(
    async () => apiRequest("/liked-videos"),
    [apiRequest]
  );

  api.buyStreamTicket = useCallback(
    async (id, type) =>
      apiRequest(`/streams/${id}/tickets`, {
        method: "POST",
        body: JSON.stringify({ type })
      }),
    [apiRequest]
  );

  api.getStreams = useCallback(
    async () => apiRequest("/streams"),
    [apiRequest]
  );

  api.getStream = useCallback(
    async (id) => apiRequest(`/streams/${id}`),
    [apiRequest]
  );

  api.getDonateRanking = useCallback(
    async (id) => apiRequest(`/streams/${id}/ranking/donations`),
    [apiRequest]
  );

  api.createStreamEvent = useCallback(
    async (id, payload) =>
      apiRequest(`/streams/${id}/events`, {
        method: "POST",
        body: JSON.stringify(payload)
      }),
    [apiRequest]
  );

  api.likeVideo = useCallback(
    async (id) =>
      apiRequest(`/liked-videos/${id}`, {
        method: "POST"
      }),
    [apiRequest]
  );

  api.unlikeVideo = useCallback(
    async (id) =>
      apiRequest(`/liked-videos/${id}`, {
        method: "DELETE"
      }),
    [apiRequest]
  );

  api.getSavedVideos = useCallback(
    async () => apiRequest("/saved-videos"),
    [apiRequest]
  );

  api.saveVideo = useCallback(
    async (id) =>
      apiRequest(`/saved-videos/${id}`, {
        method: "POST"
      }),
    [apiRequest]
  );

  api.unsaveVideo = useCallback(
    async (id) =>
      apiRequest(`/saved-videos/${id}`, {
        method: "DELETE"
      }),
    [apiRequest]
  );

  api.getUnlockedVideos = useCallback(
    async () => apiRequest("/unlocked-videos"),
    [apiRequest]
  );

  api.unlockVideo = useCallback(
    async (id) =>
      apiRequest(`/unlocked-videos/${id}`, {
        method: "POST"
      }),
    [apiRequest]
  );

  api.getFollowedCreators = useCallback(
    async () => apiRequest(`/followed-creators`),
    [apiRequest]
  );

  api.followCreator = useCallback(
    async (id) =>
      apiRequest(`/followed-creators/${id}`, {
        method: "POST"
      }),
    [apiRequest]
  );

  api.unfollowCreator = useCallback(
    async (id) =>
      apiRequest(`/followed-creators/${id}`, {
        method: "DELETE"
      }),
    [apiRequest]
  );

  api.getChats = useCallback(
    async () => apiRequest(`/chatrooms`),
    [apiRequest]
  );
  api.getChatsSettings = useCallback(
    async () => apiRequest(`/chatrooms-settings`),
    [apiRequest]
  );

  api.updateChatsSettings = useCallback(
    async ({ id, pin, mute }) =>
      apiRequest(`/chatrooms/${id}/settings`, {
        method: "PATCH",
        body: JSON.stringify({ pin, mute })
      }),
    [apiRequest]
  );

  api.createChat = useCallback(
    async ({ creatorId, type, ...payload }) =>
      apiRequest("/chatrooms", {
        method: "POST",
        body: JSON.stringify({
          creatorId,
          type,
          ...payload
        })
      }),
    [apiRequest]
  );

  api.getGifts = useCallback(async () => apiRequest(`/gifts`), [apiRequest]);

  api.getMessages = useCallback(
    async (id, start) => apiRequest(`/chatrooms/${id}/messages?from=${start}`),
    [apiRequest]
  );

  api.sendMessage = useCallback(
    async (id, payload) =>
      apiRequest(`/chatrooms/${id}/messages`, {
        method: "POST",
        body: JSON.stringify(payload)
      }),
    [apiRequest]
  );

  api.unlockMessage = useCallback(
    async (id) =>
      apiRequest(`/chatrooms/messages/${id}/unlock`, {
        method: "POST"
      }),
    [apiRequest]
  );

  api.getMessagePlayback = useCallback(
    async (id) => apiRequest(`/chatrooms/messages/${id}/playback`),
    [apiRequest]
  );

  api.markAsRead = useCallback(
    async (id) =>
      apiRequest(`/chatrooms/${id}/read`, {
        method: "POST"
      }),
    [apiRequest]
  );

  api.getPricing = useCallback(
    async (vip) => apiRequest(`/pricing?type=${vip ? "vip" : "normal"}`),
    [apiRequest]
  );

  api.depositApplePay = useCallback(
    async (payload) =>
      apiRequest(`/deposit/tappay/apple-pay`, {
        method: "POST",
        body: JSON.stringify(payload)
      }),
    [apiRequest]
  );

  api.depositGooglePay = useCallback(
    async (payload) =>
      apiRequest(`/deposit/tappay/google-pay`, {
        method: "POST",
        body: JSON.stringify(payload)
      }),
    [apiRequest]
  );

  api.deposit = useCallback(
    async (payload) =>
      apiRequest(`/deposit/tappay`, {
        method: "POST",
        body: JSON.stringify(payload)
      }),
    [apiRequest]
  );

  api.vip = useCallback(
    async (payload) =>
      apiRequest(`/vip/tappay`, {
        method: "POST",
        body: JSON.stringify(payload)
      }),
    [apiRequest]
  );

  api.getVIPCharge = useCallback(
    async (recTradeId) => apiRequest(`/vip-charges/${recTradeId}`),
    [apiRequest]
  );

  api.checkDeposit = useCallback(
    async (recTradeId) =>
      apiRequest(`/deposit/tappay/check`, {
        method: "POST",
        body: JSON.stringify({
          recTradeId
        })
      }),
    [apiRequest]
  );

  api.getDeposits = useCallback(
    async (next) => apiRequest(`/deposits?next=${next}`),
    [apiRequest]
  );

  api.getDeposit = useCallback(
    async (id) => apiRequest(`/deposits/${id}`),
    [apiRequest]
  );

  api.getTransactions = useCallback(
    async (next) => apiRequest(`/transactions?next=${next}`),
    [apiRequest]
  );

  api.getSavedCards = useCallback(
    async () => apiRequest(`/credit-cards`),
    [apiRequest]
  );

  api.getExchangeRate = useCallback(
    async () => apiRequest(`/exchange-rate`),
    [apiRequest]
  );

  api.getNotifications = useCallback(
    async () => {
      try {
        const response = await apiRequest(`/notifications`);
        console.log('API Response:', response);
        if (!response) return [];
        return response?.data || [];
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
        return [];
      }
    },
    [apiRequest]
  );

  api.getNotification = useCallback(
    async (id) => apiRequest(`/notifications/${id}`),
    [apiRequest]
  );

  return useMemo(() => api, [api]);
};

export default useAPI;
