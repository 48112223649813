import {
  Avatar,
  Box,
  Button,
  Flex,
  Square,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import FAIcon from "../FAIcon";
import StreamIncome from "./StreamIncome";
import ViewerCount from "./ViewerCount";
import useAPI from "@/libs/hooks/api";
import Pill from "../Pill";
import ShareModal from "../ShareModal";
import StreamContext from "@/context/stream";
import PinnedList from "./PinnedList";
import InputArea from "./InputArea";
import UserOverridesContext from "@/context/user-overrides";
import getPublicUrl from "@/libs/get-public-url";
import useCrisp from "@/libs/hooks/crisp";
import DonatorArea from "./DonatorArea";
import Chat from "@/components/Stream/Chat";

const IconButton = ({ icon, variant = "regular", ...props }) => (
  <Square role="button" size="1.25em" {...props}>
    <FAIcon type={icon} variant={variant} color="white" />
  </Square>
);

const StreamControls = ({
  id,
  active,
  stream,
  creator,
  muted,
  onMuteChange,
}) => {
  const { t } = useTranslation();
  const api = useAPI();
  const toast = useToast();
  const crisp = useCrisp();
  const { action } = useContext(StreamContext);
  const { overrides, overrideCreator } = useContext(UserOverridesContext);
  const [shareData, setShareData] = useState(null);
  const [chatOpen, setChatOpen] = useState(false);
  const [giftsOpen, setGiftsOpen] = useState(false);

  const followed = useMemo(() => {
    if (!creator) return false;
    const override = overrides.creators[creator.id];
    return override?.isFollowed ?? creator.isFollowed;
  }, [creator, overrides.creators]);

  const sendText = useCallback(
    async (message) =>
      await api.createStreamEvent(id, {
        type: "comment",
        data: message,
      }),
    [api, id],
  );

  const sendGift = useCallback(
    async (giftId) =>
      await api.createStreamEvent(id, { type: "gift", data: { giftId } }),
    [api, id],
  );

  const shareStream = useCallback(async () => {
    const canShare = typeof navigator?.share === "function";
    const { displayName, introduce } = creator;
    const data = {
      title: `來 NightCo 看${displayName}的直播 ♥️`,
      text: introduce,
      url: `https://${window.location.host}/stream/${id}`,
    };
    if (canShare) {
      try {
        await navigator.share(data);
        toast({ title: "已分享!", status: "info" });
      } catch (e) {
        const isAborted = e instanceof DOMException && e.name === "AbortError";
        if (!isAborted) console.error(e);
      }
    } else {
      setShareData(data);
    }
  }, [creator, toast, id]);

  const toggleFollowed = useCallback(
    async (e) => {
      if (e) e.stopPropagation();
      const action = followed ? api.unfollowCreator : api.followCreator;
      await action(creator.id);
      overrideCreator(creator.id)({ isFollowed: !followed });
    },
    [followed, api, creator, overrideCreator],
  );

  return (
    <>
      <Box position="absolute" top={0} left={0} width="100%">
        <Flex px={4} mt={3} align="center" justify="space-between" gap={3}>
          <Flex align="center" bg="translucent" rounded="full" gap={2} p={2}>
            <Avatar
              src={getPublicUrl(creator?.picture)}
              width={12}
              height={12}
            />
            <Box flex={1} width="5em" color="white" whiteSpace="nowrap">
              <Text fontSize="sm" overflow="hidden" textOverflow="ellipsis">
                {creator?.displayName}
              </Text>
              <Text fontSize="xs" overflow="hidden" textOverflow="ellipsis">
                @{creator?.slug}
              </Text>
            </Box>
            <Button
              rounded="full"
              bg="#CC51D7"
              color="white"
              onClick={toggleFollowed}
            >
              {followed
                ? t("creator.actions.followed")
                : t("creator.actions.follow")}
            </Button>
          </Flex>
          <DonatorArea id={id} />
        </Flex>
        <Flex align="center" px={6} mt={2} gap={3} justify="space-between">
          <Flex align="center" gap={4} fontSize="sm" color="white">
            <StreamIncome id={id} />
            <ViewerCount id={id} />
          </Flex>
          {
            <Pill
              bg="red.500"
              fontWeight="semibold"
              fontSize="sm"
              color="white"
            >
              LIVE
            </Pill>
          }
        </Flex>
      </Box>

      <Box position="absolute" bottom={0} left={0} width="100%">
        <Flex gap={2}>
          <Box flex={1}>
            {
              <>
                <Box px={4} pb={16}>
                  <PinnedList id={id} />
                </Box>
                <Box pb={action === "typing" ? 32 : 16} px={4}>
                  <Chat id={id} />
                </Box>
              </>
            }
            <InputArea
              active={active}
              action={action}
              sendMessage={sendText}
              sendGift={sendGift}
              onStateChange={({ chatOpen, giftsOpen }) => {
                setChatOpen(chatOpen);
                setGiftsOpen(giftsOpen);
              }}
            />
          </Box>
          {!action && !chatOpen && !giftsOpen && (
            <VStack
              justify="flex-end"
              mb={5}
              color="white"
              pr={4}
              gap={4}
              fontSize="2xl"
              position="absolute"
              right={0}
              bottom={0}
              zIndex={10}
            >
              <>
                <IconButton icon="headset" onClick={() => crisp.open()} />
                <IconButton icon="share" onClick={shareStream} />
                <IconButton
                  icon={muted ? "volume-slash" : "volume"}
                  onClick={() => onMuteChange(!muted)}
                />
              </>
            </VStack>
          )}
        </Flex>
      </Box>

      <ShareModal data={shareData} onClose={() => setShareData(null)} />
    </>
  );
};

export default StreamControls;
