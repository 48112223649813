import { Box, Flex, Skeleton, Text, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFirebase } from "@/libs/hooks/firebase";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useIsCreator } from "@/libs/hooks/useIsCreator";
import dayjs from "dayjs";
import UserAvatar from "@/components/UserAvatar";
import ChatUserName from "@/components/ChatUserName";

const CreatorChatsPage = () => {
  const navigate = useNavigate();
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const { firestore } = useFirebase();
  const { data: creatorData } = useIsCreator();

  useEffect(() => {
    if (!creatorData?.creatorId) return;

    const chatroomsRef = collection(firestore, "chatrooms");
    const q = query(
      chatroomsRef,
      where("creatorId", "==", creatorData.creatorId),
      orderBy("lastMessagedAt", "desc"),
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const chatData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setChats(chatData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [firestore, creatorData]);

  const handleChatClick = (chatId) => {
    navigate(`/creator/chats/${chatId}`);
  };

  if (loading) {
    return (
      <VStack p={4} spacing={4} align="stretch">
        {[...Array(5)].map((_, i) => (
          <Skeleton key={i} height="80px" />
        ))}
      </VStack>
    );
  }

  return (
    <Box p={4}>
      <Text fontSize="2xl" fontWeight="bold" mb={6} color="white">
        聊天室列表
      </Text>

      <VStack spacing={4} align="stretch">
        {chats.map((chat) => (
          <Flex
            key={chat.id}
            bg="whiteAlpha.100"
            p={4}
            borderRadius="md"
            cursor="pointer"
            onClick={() => handleChatClick(chat.id)}
            _hover={{ bg: "whiteAlpha.200" }}
          >
            <Box mr={4}>
              <UserAvatar userId={chat.userId} size="md" />
            </Box>
            <Box flex={1}>
              <Flex justify="space-between" align="center" mb={1}>
                <Text color="white" fontWeight="bold">
                  <ChatUserName userId={chat.userId} />
                </Text>
                <Text color="whiteAlpha.700" fontSize="sm">
                  {dayjs(chat.lastMessagedAt).format("MM/DD HH:mm")}
                </Text>
              </Flex>
              <Text color="whiteAlpha.800" noOfLines={1}>
                {chat.lastMessage?.text || ""}
              </Text>
            </Box>
          </Flex>
        ))}

        {!loading && chats.length === 0 && (
          <Flex
            justify="center"
            align="center"
            height="200px"
            color="whiteAlpha.700"
          >
            目前沒有聊天記錄
          </Flex>
        )}
      </VStack>
    </Box>
  );
};

export default CreatorChatsPage;
