import { PlayerStore } from "@/libs/stores/player.store";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Playlist from "@/components/Playlist";
import { Box } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useVideosFeed } from "@/libs/queries";

const shortsStore = new PlayerStore();
const PlaylistPage = () => {
  const location = useLocation();
  const videoId =
    shortsStore.getActivePlayer()?.id ?? location.pathname.split("/")[2];

  const [initializing, setInitializing] = useState(
    shortsStore.videos.length === 0,
  );

  const { videos, isLoading, hasMore, loadMore } = useVideosFeed(
    videoId,
    initializing,
  );

  // 統一處理視頻加載和隊列更新
  useEffect(() => {
    if ((!videos.length && !shortsStore.videos.length) || isLoading) return;

    const currentQueueIds = new Set(shortsStore.videos.map((v) => v.id));
    const newVideos = videos.filter((v) => !currentQueueIds.has(v.id));

    newVideos.forEach((video) => {
      shortsStore.inQueue(video);
    });

    if (!shortsStore.active) {
      shortsStore.openPlayList(shortsStore.currentIndex || 0);
    }

    if (!initializing) {
      setInitializing(true);
    }
  }, [videos, isLoading]);

  useEffect(() => {
    return () => {
      shortsStore.unloadAndSavePreload();
      shortsStore.active = false;
    };
  }, []);

  return (
    <Box position="absolute" top={0} left={{ base: 0, md: 320 }} right={0}>
      {shortsStore.active && (
        <Playlist
          loader={loadMore}
          loading={isLoading}
          done={!hasMore}
          store={shortsStore}
        />
      )}
    </Box>
  );
};

export default observer(PlaylistPage);
