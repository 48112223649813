import { APIClient } from "@/libs/api.ts";
import firebase from "@/libs/firebase.ts";
import { QueryClient } from "@tanstack/react-query";
import { collection, onSnapshot, query, where, doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { queryKeys } from "../queries";

const firestore = firebase.firestore;

export interface StreamData {
  id?: string;
  creatorId: string;
  status: "LIVE" | "ENDED" | null;
  viewersTotal: number;
  income: number;
  startedAt: number;
  endedAt?: number;
  agoraRoomId: string;
  coverImage: string;
  title: string;
  guestToken: string;
}

const STREAM_SERVER = "https://creatorapi-n6xhaffeza-de.a.run.app";

class StreamAPI extends APIClient {
  constructor() {
    super(STREAM_SERVER);
  }

  async startStream(data: Partial<StreamData>): Promise<StreamData> {
    return this.apiRequest("/stream", {
      method: "POST",
      body: JSON.stringify({
        title: data.title,
      }),
    });
  }

  async endStream(streamId: string): Promise<void> {
    const streamRef = doc(firestore, "streams", streamId);
    await updateDoc(streamRef, {
      status: "ENDED",
      endedAt: serverTimestamp(),
    });
  }

  async updateStream(streamId: string, data: Partial<StreamData>) {
    const streamRef = doc(firestore, "streams", streamId);
    await updateDoc(streamRef, {
      ...data,
      updatedAt: serverTimestamp(),
    });
  }

  async getLiveStreams() {
    return this.apiRequest("/streams/live");
  }
}

export const streamAPI = new StreamAPI();

export const subscribeToStreams = (queryClient: QueryClient) => {
  const streamQuery = query(
    collection(firestore, "streams"),
    where("status", "==", "LIVE"),
  );

  const unsubscribe = onSnapshot(streamQuery, (snapshot) => {
    const streams = snapshot.docs.map((doc) => {
      const data = { id: doc.id, ...doc.data() } as StreamData;
      queryClient.setQueryData(["stream", data.id], data);
      return data;
    });

    queryClient.setQueryData([queryKeys.streams, "live"], streams);
  });

  return unsubscribe;
};
