import { useQuery } from "@tanstack/react-query";
import { useFirebase } from "./firebase";
import { doc, getDoc } from "firebase/firestore";

export const useUser = (userId) => {
  const { firestore } = useFirebase();

  return useQuery({
    queryKey: ['user', userId],
    queryFn: async () => {
      if (!userId) return null;
      const userDoc = await getDoc(doc(firestore, 'users', userId));
      if (!userDoc.exists()) return null;
      return userDoc.data();
    },
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
    cacheTime: 24 * 60 * 60 * 1000, // 24 hours
    enabled: !!userId
  });
};
