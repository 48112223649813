import { Box } from "@chakra-ui/react";
import { useEffect } from "react";
import useDeviceInfo from "@/libs/hooks/device-info";
import MobileNavigation from "../MobileNavigation";
import AgoraAudienceView from "@/components/AgoraAudienceView";
import useAPI from "@/libs/hooks/api";

const Live = ({ id, active, muted }) => {
  const { width, height } = useDeviceInfo();
  const api = useAPI();

  useEffect(() => {
    if (!id) return;
    api.createStreamEvent(id, { type: "entry" });
  }, [api, id]);

  return (
    <Box
      position="relative"
      height="100%"
      aspectRatio={{
        base: width / (height - MobileNavigation.HEIGHT),
        md: 9 / 16,
      }}
      width="100%"
    >
      <Box
        width="100%"
        height="100%"
        overflow="hidden"
        position="relative"
        borderRadius={{ md: ".5rem" }}
      >
        <Box position="absolute" height="100%" width="100%" bg="black">
          <AgoraAudienceView streamId={id} muted={muted} active={active} />
        </Box>
      </Box>
    </Box>
  );
};

export default Live;
