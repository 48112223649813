import { Text } from "@chakra-ui/react";
import { useUser } from "@/libs/hooks/useUser";

const ChatUserName = ({ userId }) => {
  const { data: userData } = useUser(userId);

  return (
    <Text color="white" fontWeight="bold">
      {userData?.displayName || "使用者"}
    </Text>
  );
};

export default ChatUserName;
