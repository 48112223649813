import { Box, Flex, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import FAIcon from "@/components/FAIcon";
import { creatorStore } from "@/libs/stores/creator.store";
import MobXCreatorAvatar from "@/components/MobXCreatorAvatar";

const GroupTitle = ({ name }) => (
  <Flex
    justify="space-between"
    fontWeight="bold"
    color="white"
    align="center"
    as={motion.div}
    initial={{ opacity: 0, y: 10 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.3, ease: "easeOut" }}
  >
    <Text
      fontSize="xl"
      maxW="80%"
      sx={{
        wordBreak: "break-word",
        whiteSpace: "pre-wrap",
      }}
    >
      {name}
    </Text>
    <Box as={Link} to="/explore/creators" fontSize="sm" mr={3}>
      <FAIcon type="angle-right" mr={1} /> 探索更多
    </Box>
  </Flex>
);

const CreatorAvatar = ({ item, index }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.9 }}
    whileInView={{ opacity: 1, scale: 1 }}
    viewport={{ once: true }}
    transition={{
      duration: 0.2,
      delay: index < 5 ? index * 0.05 : 0,
      ease: "easeOut",
    }}
  >
    <MobXCreatorAvatar id={item} role="button" size="lg" mr="5px" />
  </motion.div>
);

const CreatorGroup = observer(({ name, items = [] }) => {
  const data = [...items].sort((a, b) => {
    const isAOnline = creatorStore.getCreator(a).isOnline || false;
    const isBOnline = creatorStore.getCreator(b).isOnline || false;
    return isBOnline - isAOnline;
  });

  return (
    <Box mt={4} pl={6}>
      <GroupTitle name={name} />
      <Box position="relative" my={6} pr={6}>
        <Flex
          as={motion.div}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.4, ease: "easeOut" }}
          gap={{ base: 2, md: 3 }}
          overflow="auto"
          sx={{
            "::-webkit-scrollbar": {
              display: "none",
            },
            scrollSnapType: "x mandatory",
          }}
        >
          {data.map((item, index) => (
            <Box
              key={`creator-${item}-${index}`}
              flexShrink={0}
              scrollSnapAlign="start"
            >
              <CreatorAvatar item={item} index={index} />
            </Box>
          ))}
        </Flex>
      </Box>
    </Box>
  );
});

export default CreatorGroup;
