import MobileNavigation from "@/components/MobileNavigation";
import Stream from "@/components/Stream";
import { useLiveStreams } from "@/libs/queries";
import useDeviceInfo from "@/libs/hooks/device-info";
import usePolyfills from "@/libs/hooks/polyfills";
import { Box, Image, Square, Text, VStack } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { Mousewheel, Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import streamPlaceholder from "@/assets/images/stream-placeholder.png";
import { useNavigate, useParams } from "react-router-dom";

const StreamsPage = () => {
  const { height, isMobile } = useDeviceInfo();
  const { id } = useParams();
  const navigate = useNavigate();
  const { getMaxHeightRepresentation } = usePolyfills();
  const fullscreen = getMaxHeightRepresentation();
  const { data: streams } = useLiveStreams();
  const [swiper, setSwiper] = useState(null);
  const [active, setActive] = useState(0);

  const updateUrl = useCallback(
    (streamId) =>
      navigate(`/streams/${streamId}`, { replace: true, shallow: true }),
    [navigate],
  );

  const onRefresh = useCallback(async () => {
    // Refresh is handled automatically by the query
  }, []);

  const onSlideChange = useCallback(
    ({ activeIndex }) => {
      setActive(activeIndex);
      const activeId = streams[activeIndex]?.id;
      if (activeId) updateUrl(activeId);
    },
    [updateUrl, streams],
  );

  useEffect(() => {
    if (streams && swiper) {
      const index = id ? streams.findIndex((s) => s.id === id) : 0;
      if (index === -1) return;
      if (index === 0) {
        const streamId = streams[index]?.id;
        updateUrl(streamId);
      }
      swiper.slideTo(index);
    }
  }, [id, streams, swiper, updateUrl]);

  return (
    <Box
      height={{
        base: `calc(${fullscreen} - ${MobileNavigation.HEIGHT}px)`,
        md: fullscreen,
      }}
      overflow="hidden"
      bg="primary.100"
    >
      {streams?.length ? (
        <Swiper
          direction="vertical"
          height={height - MobileNavigation.HEIGHT}
          modules={[Mousewheel, Virtual]}
          slidesPerView={1}
          onSwiper={setSwiper}
          onSlideChange={onSlideChange}
          allowTouchMove={isMobile}
          touchReleaseOnEdges={true}
          threshold={0}
          resistanceRatio={0.5}
          mousewheel={{
            forceToAxis: true,
            sensitivity: 0,
            thresholdDelta: 30,
            thresholdTime: 70,
          }}
          virtual={{
            addSlidesBefore: 2,
            addSlidesAfter: 2,
          }}
        >
          {streams.map((stream, index) => (
            <SwiperSlide key={`${stream.id}-${index}`} virtualIndex={index}>
              <Stream id={stream.id} active={active === index} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <VStack align="center" justify="center" h="100%">
          <Square size={36}>
            <Image src={streamPlaceholder} />
          </Square>
          <Text mt={3} color="white" align="center">
            場館整理中 <br />
            請等候開播通知
          </Text>
        </VStack>
      )}
    </Box>
  );
};

export default StreamsPage;
