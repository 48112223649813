import { Avatar } from "@chakra-ui/react";
import { useUser } from "@/libs/hooks/useUser";
import getPublicUrl from "@/libs/get-public-url";

const UserAvatar = ({ userId, size = "sm" }) => {
  const { data: userData } = useUser(userId);

  return (
    <Avatar
      size={size}
      name={userData?.displayName || "使用者"}
      src={getPublicUrl(userData?.picture)}
    />
  );
};

export default UserAvatar;
