import { Box, Center, Link, VStack } from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import DepositPlan from "@/components/DepositPlan";
import useAPI from "@/libs/hooks/api";
import { useOutletContext } from "react-router-dom";
import AuthContext from "@/context/auth";
import useCrisp from "@/libs/hooks/crisp";

const DepositPage = () => {
  const api = useAPI();
  const crisp = useCrisp();
  const { user } = useContext(AuthContext);
  const { currency, exchangeRates, onSelect } = useOutletContext();
  const [pricing, setPricing] = useState([]);

  const vip = !!user?.isVip;

  const selectPlan = useCallback(
    (plan) => () => onSelect({ ...plan, type: "deposit" }),
    [onSelect],
  );

  const otcRequest = useCallback(() => {
    // crisp.send("text", "大額儲值");
    crisp.open();
  }, [crisp]);

  useEffect(() => {
    api.getPricing(vip).then(setPricing);
  }, [api, vip]);

  return (
    <Box>
      <VStack color="white" align="stretch" gap={2}>
        {pricing.map((plan) => (
          <DepositPlan
            key={plan.cost}
            onSelect={selectPlan(plan)}
            rate={currency === "TWD" ? 1 : exchangeRates[currency]}
            {...plan}
          />
        ))}
      </VStack>
      <Center py={4}>
        <Link
          color="secondary.100"
          textDecoration="underline"
          onClick={otcRequest}
          fontSize="md"
        >
          更多儲值方式，請洽客服人員
        </Link>
      </Center>
    </Box>
  );
};

export default DepositPage;
