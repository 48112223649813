import { useQuery } from "@tanstack/react-query";
import AgoraRTC from "agora-rtc-sdk-ng";

export const useAgoraClient = () => {
  return useQuery({
    queryKey: ["agoraClient"],
    queryFn: () => AgoraRTC.createClient({ mode: "live", codec: "vp8" }),
    staleTime: Infinity, // Never mark as stale
    cacheTime: Infinity, // Never remove from cache
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
