import { Box } from "@chakra-ui/react";
import Live from "./Live";
import { useQuery } from "@tanstack/react-query";
import { useCreator } from "@/libs/queries";
import { useState } from "react";
import StreamControls from "./StreamControls";

const Stream = ({ id, active }) => {
  const { data: stream } = useQuery({
    queryKey: ["stream", id],
    enabled: !!id,
  });

  const { data: creator } = useCreator(stream?.creatorId);
  const [muted, setMuted] = useState(true);

  return (
    <Box
      position="relative"
      height="100%"
      maxW={{ base: "none", md: 500 }}
      mx="auto"
      py={{ base: 0, md: 5 }}
    >
      {stream?.status === "LIVE" && (
        <Box position="relative">
          <Live id={id} active={active} muted={muted} />
          <StreamControls
            id={id}
            active={active}
            stream={stream}
            creator={creator}
            muted={muted}
            onMuteChange={setMuted}
          />
        </Box>
      )}
    </Box>
  );
};

export default Stream;
