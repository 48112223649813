import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Skeleton,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "@/context/auth";
import {
  collection,
  doc,
  limit,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useFirebase } from "@/libs/hooks/firebase";
import MobXCreatorAvatar from "@/components/MobXCreatorAvatar";
import FAIcon from "@/components/FAIcon";
import toDisplayNumber from "@/libs/to-display-number";

const CreatorProfile = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { firestore } = useFirebase();
  const [creator, setCreator] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();

  const [isEditingIntro, setIsEditingIntro] = useState(false);
  const [newIntroduce, setNewIntroduce] = useState("");

  useEffect(() => {
    if (!user?.uid) return;
    setIsLoading(true);

    const creatorsRef = collection(firestore, "creators");
    const q = query(creatorsRef, where("userId", "==", user.uid), limit(1));

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        if (!snapshot.empty) {
          const creatorData = {
            id: snapshot.docs[0].id,
            ...snapshot.docs[0].data(),
          };
          setCreator(creatorData);
          setNewIntroduce(creatorData.introduce || "");
        } else {
          // If no creator document found, redirect to home
          navigate("/");
        }
        setIsLoading(false);
      },
      (error) => {
        console.error("Error fetching creator:", error);
        toast({
          title: "載入失敗",
          status: "error",
          duration: 3000,
        });
        setIsLoading(false);
        navigate("/");
      },
    );

    return () => unsubscribe();
  }, [user?.uid, firestore, toast, navigate]);

  return (
    <Box bg="primary.100" color="white" minH="100vh">
      <Flex py={5} px={8} align="center" textAlign="center" gap={8}>
        <Skeleton isLoaded={!isLoading} borderRadius="full">
          <Box width="80px" height="80px">
            {creator?.id && <MobXCreatorAvatar id={creator.id} size="xl" />}
          </Box>
        </Skeleton>
        <Box flex={1} textAlign="left">
          <Skeleton isLoaded={!isLoading}>
            <Text fontSize="xl" fontWeight="bold">
              {creator?.displayName || " "}
            </Text>
            <Text fontSize="sm" color="whiteAlpha.800">
              {creator?.slug ? `@${creator.slug}` : " "}
            </Text>
          </Skeleton>
        </Box>
      </Flex>

      <Box px={6} mb={6}>
        <StatGroup>
          <SimpleGrid columns={3} spacing={10} w="full">
            <Stat>
              <StatLabel color="whiteAlpha.800">總追蹤人數</StatLabel>
              <Skeleton isLoaded={!isLoading}>
                <StatNumber color="white">
                  {toDisplayNumber(creator?.followersTotal || 0)}
                </StatNumber>
              </Skeleton>
            </Stat>
            <Stat>
              <StatLabel color="whiteAlpha.800">影片數量</StatLabel>
              <Skeleton isLoaded={!isLoading}>
                <StatNumber color="white">
                  {toDisplayNumber(creator?.videosTotal || 0)}
                </StatNumber>
              </Skeleton>
            </Stat>
            <Stat>
              <StatLabel color="whiteAlpha.800">總收益</StatLabel>
              <Skeleton isLoaded={!isLoading}>
                <StatNumber color="white">
                  {toDisplayNumber(creator?.points?.balance || 0)}
                </StatNumber>
              </Skeleton>
            </Stat>
          </SimpleGrid>
        </StatGroup>
      </Box>

      <Box px={6} mb={4}>
        <Box bg="whiteAlpha.100" p={4} borderRadius="md" mb={4}>
          <Flex justify="space-between" align="center" mb={2}>
            <Text fontSize="lg" fontWeight="bold">
              自我介紹
            </Text>
            <Button
              size="sm"
              leftIcon={<FAIcon type={isEditingIntro ? "check" : "pen"} />}
              onClick={async () => {
                if (isEditingIntro) {
                  try {
                    const creatorRef = doc(firestore, "creators", creator.id);
                    await updateDoc(creatorRef, {
                      introduce: newIntroduce,
                    });
                    toast({
                      title: "更新成功",
                      status: "success",
                      duration: 3000,
                    });
                  } catch (error) {
                    console.error("Error updating introduction:", error);
                    toast({
                      title: "更新失敗",
                      status: "error",
                      duration: 3000,
                    });
                  }
                }
                setIsEditingIntro(!isEditingIntro);
              }}
            >
              {isEditingIntro ? "儲存" : "編輯"}
            </Button>
          </Flex>
          {isEditingIntro ? (
            <Textarea
              value={newIntroduce}
              onChange={(e) => setNewIntroduce(e.target.value)}
              placeholder="請輸入自我介紹..."
              resize="vertical"
              rows={4}
            />
          ) : (
            <Text whiteSpace="pre-wrap">
              {creator?.introduce || "尚未設定自我介紹"}
            </Text>
          )}
        </Box>
        {/*<Button*/}
        {/*  leftIcon={<FAIcon type="video" />}*/}
        {/*  colorScheme="blue"*/}
        {/*  width="100%"*/}
        {/*  onClick={() => navigate("/creator/streams")}*/}
        {/*>*/}
        {/*  開始直播*/}
        {/*</Button>*/}
      </Box>
    </Box>
  );
};

export default CreatorProfile;
