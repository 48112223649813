import {
  Avatar,
  AvatarGroup,
  Box,
  Collapse,
  Flex,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import firebase from "@/libs/firebase";
import AuthContext from "@/context/auth";
import getPublicUrl from "@/libs/get-public-url";
import DonatorList from "./DonatorList";

const DonatorArea = ({ id }) => {
  const donatorListRef = useRef(null);
  const {
    isOpen: isDonatorListOpen,
    onOpen: openDonatorList,
    onClose: closeDonatorList,
  } = useDisclosure();

  const [donators, setDonators] = useState([]);
  const [userDonate, setUserDonate] = useState(0);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (!id) return;

    const unsubscribe = onSnapshot(
      query(
        collection(firebase.firestore, "stream-events"),
        where("streamId", "==", id),
        // where("type", "in", ["comment", "gift"]),
      ),
      (snapshot) => {
        // 計算每個用戶的總打賞金額
        const userTotals = new Map();

        snapshot.forEach((doc) => {
          const data = doc.data();
          const userId = data.user.id;
          const value = data.data?.value || 0;

          const currentTotal = userTotals.get(userId) || {
            id: userId,
            name: data.user.displayName,
            picture: data.user.picture,
            total: 0,
          };

          currentTotal.total += value;
          userTotals.set(userId, currentTotal);
        });

        // 轉換成陣列並排序
        const sortedDonators = Array.from(userTotals.values())
          .sort((a, b) => b.total - a.total)
          .slice(0, 50) // 只取前50名
          .map((donator, index) => ({
            ...donator,
            rank: index + 1,
          }));

        // 設定當前用戶的打賞金額
        if (user) {
          const currentUserTotal = userTotals.get(user.uid)?.total || 0;
          setUserDonate(currentUserTotal);
        }

        setDonators(sortedDonators);
      },
    );

    return () => unsubscribe();
  }, [id, user]);

  useOutsideClick({
    ref: donatorListRef,
    handler: closeDonatorList,
  });

  return (
    <>
      <Flex align="center" gap={2}>
        <AvatarGroup
          role="button"
          size="md"
          max={2}
          spacing={-5}
          onClick={openDonatorList}
        >
          {donators.map((donator) => (
            <Avatar
              key={donator.id}
              name={donator.name}
              src={getPublicUrl(donator.picture)}
              border="none"
            />
          ))}
        </AvatarGroup>
      </Flex>

      <Box
        position="fixed"
        zIndex={20}
        bottom={0}
        left={0}
        width="100%"
        maxW={{ base: "none", md: 500 }}
        mx="auto"
        ref={donatorListRef}
      >
        <Collapse in={isDonatorListOpen} animateOpacity={false}>
          <DonatorList donators={donators} userDonate={userDonate} />
        </Collapse>
      </Box>
    </>
  );
};

export default DonatorArea;
