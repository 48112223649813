import useDeviceInfo from "@/libs/hooks/device-info";
import {
  Box,
  Button,
  Circle,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Square,
  Text,
  VStack,
} from "@chakra-ui/react";
import safariMoreOptions from "@/assets/images/safari-more-options.png";
import chromeInstallPWA from "@/assets/images/chrome-install-pwa.png";
import FAIcon from "@/components/FAIcon";
import { makeAutoObservable, runInAction } from "mobx";
import { observer } from "mobx-react-lite";

import { motion } from "motion/react";
import MobileNavigation from "@/components/MobileNavigation";

const InstallAppDrawer = observer(() => {
  const { isIPhone } = useDeviceInfo();
  const { isMobile } = useDeviceInfo();
  console.log("isMobile", isMobile);
  if (!isMobile) return null;
  const handleHelpClick = () =>
    window.open(
      "https://nightco.crisp.help/zh-tw/article/app-e7no7p/",
      "_blank",
    );
  const handleClose = () => installAppStore.close();
  const handleRemindLater = () => installAppStore.closeAndRemindLater();

  // 動畫設定
  const slideIn = {
    initial: { opacity: 0, x: 100 },
    animate: { opacity: 1, x: 0 },
    transition: {
      duration: 0.4,
      ease: "easeOut",
    },
  };

  const stepAnimation = {
    initial: { opacity: 0, y: 50, scale: 0.8 },
    animate: { opacity: 1, y: 0, scale: 1 },
    transition: {
      duration: 0.6,
      scale: {
        type: "spring",
        damping: 5,
        stiffness: 100,
        bounce: 0.8,
      },
    },
  };

  const staggerContainer = {
    animate: {
      transition: {
        staggerChildren: 0.6,
        delayChildren: 0,
      },
    },
  };

  const staggerSteps = {
    animate: {
      transition: {
        staggerChildren: 0.4,
        delayChildren: 0.2,
      },
    },
  };

  const circleStyle = {
    size: 16,
    boxShadow: "0 0 .5em .1rem #cecece",
    p: 3,
  };

  return (
    <>
      <Drawer
        isOpen={installAppStore.isOpen}
        onClose={handleClose}
        placement="bottom"
      >
        <DrawerOverlay />
        <DrawerContent
          borderTopRadius="xl"
          bg="rgba(255,255,255,100%)"
          bgImage="url('https://assets.nightco.io/app/nightco-pattern.png')"
          mb={`${isMobile ? MobileNavigation.HEIGHT : 0}px`}
        >
          <>
            <motion.div>
              <DrawerHeader
                borderColor="secondary.100"
                borderBottomWidth={4}
                color="#212121"
                textAlign="center"
              >
                NightCo，專屬於你的私密空間！
              </DrawerHeader>
            </motion.div>

            <DrawerBody py={8}>
              {/* 吸引力文案 */}
              <motion.div
                variants={staggerContainer}
                initial="initial"
                animate="animate"
              >
                <Box textAlign="center" mb={6}>
                  <VStack spacing={2}>
                    <motion.div variants={slideIn}>
                      <Text fontSize="sm" fontWeight="600" color="gray.700">
                        - 加到主畫面，無痕體驗更隱私
                      </Text>
                    </motion.div>
                    <motion.div variants={slideIn}>
                      <Text fontSize="sm" fontWeight="600" color="gray.700">
                        - 全螢幕模式，沉浸感加倍
                      </Text>
                    </motion.div>
                    <motion.div variants={slideIn}>
                      <Text fontSize="sm" fontWeight="600" color="gray.700">
                        - 即時通知，精彩互動不錯過
                      </Text>
                    </motion.div>
                  </VStack>
                </Box>
              </motion.div>

              {/* 安裝步驟 */}
              <motion.div
                variants={staggerSteps}
                initial="initial"
                animate="animate"
              >
                <Flex align="center" justify="center" gap={4}>
                  <motion.div variants={stepAnimation}>
                    <VStack align="center" gap={3}>
                      <Circle {...circleStyle}>
                        {isIPhone ? (
                          <Image src={safariMoreOptions} />
                        ) : (
                          <FAIcon
                            type="circle-ellipsis-vertical"
                            fontSize="4xl"
                          />
                        )}
                      </Circle>
                      <Text align="center" fontSize="xs" fontWeight="600">
                        點擊
                      </Text>
                    </VStack>
                  </motion.div>

                  <Box opacity={0.3} pb={6}>
                    <FAIcon
                      type="arrow-right-long"
                      color="gray.700"
                      fontSize="xl"
                    />
                  </Box>

                  <motion.div variants={stepAnimation}>
                    <VStack align="center" gap={3}>
                      <Circle {...circleStyle}>
                        {isIPhone ? (
                          <FAIcon type="square-plus" fontSize={32} />
                        ) : (
                          <Square size={8} bg="#212121" p={1.5} rounded="md">
                            <Image src={chromeInstallPWA} />
                          </Square>
                        )}
                      </Circle>
                      <Text align="center" fontSize="xs" fontWeight="600">
                        加到主畫面
                      </Text>
                    </VStack>
                  </motion.div>

                  <Box opacity={0.3} pb={6}>
                    <FAIcon
                      type="arrow-right-long"
                      color="gray.700"
                      fontSize="xl"
                    />
                  </Box>

                  <motion.div variants={stepAnimation}>
                    <VStack align="center" gap={3}>
                      <Circle {...circleStyle}>
                        <Image
                          src="https://assets.nightco.io/shared/logo.png"
                          rounded="2xl"
                        />
                      </Circle>
                      <Text align="center" fontSize="xs" fontWeight="600">
                        NightCo
                      </Text>
                    </VStack>
                  </motion.div>
                </Flex>
              </motion.div>

              {/* 按鈕 */}
              <motion.div
                variants={staggerContainer}
                initial="initial"
                animate="animate"
              >
                <VStack align="center" mt="20px" spacing={4}>
                  <motion.div>
                    <Button
                      variant="themed-gradient"
                      onClick={handleHelpClick}
                      width={200}
                    >
                      查看操作教學
                    </Button>
                  </motion.div>
                  <motion.div>
                    <Button
                      variant="themed-cancel"
                      onClick={handleRemindLater}
                      width={200}
                    >
                      稍後再說
                    </Button>
                  </motion.div>
                </VStack>
              </motion.div>
            </DrawerBody>
          </>
        </DrawerContent>
      </Drawer>
    </>
  );
});
export default InstallAppDrawer;

class InstallAppStore {
  isOpen = false;
  STORAGE_KEY = "install-app-last-dismissed";
  WEEK_IN_MS = 7 * 24 * 60 * 60 * 1000; // 一週的毫秒數

  constructor() {
    makeAutoObservable(this);
  }

  open() {
    const lastDismissed = localStorage.getItem(this.STORAGE_KEY);
    const now = Date.now();

    // 如果從未關閉過或已經過了一週，才顯示
    if (!lastDismissed || now - Number(lastDismissed) > this.WEEK_IN_MS) {
      setTimeout(() => {
        runInAction(() => {
          this.isOpen = true;
        });
      }, 100);
    }
  }

  close() {
    this.isOpen = false;
  }

  closeAndRemindLater() {
    this.isOpen = false;
    localStorage.setItem(this.STORAGE_KEY, Date.now().toString());
  }
}

export const installAppStore = new InstallAppStore();
