import { Box, Text } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useIsCreator } from "@/libs/hooks/useIsCreator";
import Header from "@/components/Header";
import FAIcon from "@/components/FAIcon";
import { useEffect } from "react";
import { uiStore } from "@/libs/stores/ui.store";
import CreatorChat from "@/pages/creator/components/CreatorChat";

const CreatorChatPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: creatorData } = useIsCreator();

  const handleBack = () => {
    navigate("/creator/chats");
  };

  useEffect(() => {
    uiStore.setShowHeaderNav(false);
    return () => uiStore.setShowHeaderNav(true);
  }, []);

  if (!creatorData?.creatorId) {
    return null;
  }

  return (
    <Box h="100vh" display="flex" flexDirection="column" bg="gray.900">
      <Header>
        <Box px={3} role="button" onClick={handleBack}>
          <FAIcon type="chevron-left" fontSize="2xl" />
        </Box>
        <Text>聊天室</Text>
      </Header>
      <Box flex="1" overflow="hidden">
        <CreatorChat chatroomId={id} creatorId={creatorData.creatorId} />
      </Box>
    </Box>
  );
};

export default CreatorChatPage;
