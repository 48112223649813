import {
  Box,
  Collapse,
  Flex,
  IconButton,
  Input,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { useCallback, useState } from "react";
import Chat from "@/components/Stream/Chat";
import PinnedList from "./PinnedList";
import useAPI from "@/libs/hooks/api";
import StreamIncome from "./StreamIncome";
import ViewerCount from "./ViewerCount";
import DonatorArea from "./DonatorArea";
import StreamDuration from "./StreamDuration";
import FAIcon from "../FAIcon";

const CreatorStreamControls = ({
  id,
  duration,
  localTracks,
  active,
  onLeave,
  onOpenSettings,
  isPreview,
  onStartStream,
}) => {
  const api = useAPI();
  const toast = useToast();
  const [message, setMessage] = useState("");
  const { isOpen: inputOpen, onToggle: toggleInput } = useDisclosure();

  const sendText = useCallback(
    async (message) =>
      await api.createStreamEvent(id, {
        type: "comment",
        data: message,
      }),
    [api, id],
  );

  const onMessageSend = useCallback(() => {
    if (!message.trim()) return;
    sendText({ text: message.trim() });
    setMessage("");
  }, [message, sendText]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onMessageSend();
    }
  };

  return (
    <>
      <Box position="absolute" top={0} left={0} width="100%">
        <Flex align="center" px={6} mt={2} gap={3} justify="space-between">
          <StreamDuration duration={duration} />
          <Flex align="center" gap={4} fontSize="sm" color="white">
            <StreamIncome id={id} />
            <ViewerCount id={id} />
          </Flex>
          <DonatorArea id={id} />
        </Flex>
      </Box>

      <Box position="absolute" bottom={0} left={0} width="100%">
        <Box pb={16} px={4}>
          <PinnedList id={id} />
          <Chat id={id} />
        </Box>

        <Box position="relative">
          <Collapse in={true} animateOpacity={false}>
            <Flex px={4} py={2} justify="space-between" gap={2}>
              {/*<Circle*/}
              {/*  role="button"*/}
              {/*  bg="#2D2D2D77"*/}
              {/*  size={10}*/}
              {/*  onClick={toggleInput}*/}
              {/*>*/}
              {/*  <ChatIcon color="white" />*/}
              {/*</Circle>*/}

              <Flex gap={2}>
                <IconButton
                  icon={<FAIcon type="camera-rotate" />}
                  onClick={async () => {
                    try {
                      const devices = await AgoraRTC.getCameras();
                      if (devices.length <= 1) {
                        toast({
                          title: "無可用鏡頭",
                          description: "找不到其他鏡頭裝置",
                          status: "warning",
                          duration: 3000,
                        });
                        return;
                      }

                      const currentDevice = await localTracks.videoTrack
                        .getMediaStreamTrack()
                        .getSettings();

                      const currentIndex = devices.findIndex(
                        (device) => device.deviceId === currentDevice.deviceId,
                      );
                      const nextDevice =
                        devices[(currentIndex + 1) % devices.length];

                      const newVideoTrack =
                        await AgoraRTC.createCameraVideoTrack({
                          cameraId: nextDevice.deviceId,
                        });

                      await localTracks.videoTrack.replaceTrack(
                        newVideoTrack.getMediaStreamTrack(),
                      );

                      toast({
                        title: "已切換鏡頭",
                        status: "success",
                        duration: 2000,
                      });
                    } catch (error) {
                      console.error("切換鏡頭失敗:", error);
                      toast({
                        title: "切換鏡頭失敗",
                        description: error.message,
                        status: "error",
                        duration: 3000,
                      });
                    }
                  }}
                  colorScheme="gray"
                  rounded="full"
                  aria-label="Switch Camera"
                />
                <IconButton
                  icon={<FAIcon type="gear" />}
                  onClick={onOpenSettings}
                  colorScheme="gray"
                  rounded="full"
                  aria-label="Settings"
                />
                {isPreview ? (
                  <IconButton
                    icon={<FAIcon type="play" />}
                    onClick={onStartStream}
                    colorScheme="green"
                    rounded="full"
                    aria-label="Start Stream"
                  />
                ) : (
                  <IconButton
                    icon={<FAIcon type="stop" />}
                    onClick={onLeave}
                    colorScheme="red"
                    rounded="full"
                    aria-label="End Stream"
                  />
                )}
              </Flex>
            </Flex>
          </Collapse>

          <Collapse in={inputOpen} animateOpacity={false}>
            <Box bg="linear-gradient(to right, #D7913F, #A86A31)" p={4}>
              <Flex gap={2}>
                <Input
                  flex={1}
                  bg="white"
                  color="black"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyPress={handleKeyPress}
                  placeholder="輸入訊息..."
                  _placeholder={{ color: "gray.500" }}
                  rounded="full"
                />
                <IconButton
                  icon={<FAIcon type="send" />}
                  onClick={onMessageSend}
                  isDisabled={!message.trim()}
                  rounded="full"
                  colorScheme="orange"
                  aria-label="Send message"
                />
              </Flex>
            </Box>
          </Collapse>
        </Box>
      </Box>
    </>
  );
};

export default CreatorStreamControls;
