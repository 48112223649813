import { Suspense, useEffect } from "react";
import { AliveScope, KeepAlive } from "react-activation";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AuthLayout from "@/layouts/auth";
import { lazyWithPreload as preload } from "react-lazy-with-preload";
import withContext from "@/context";
import withDebug from "@/libs/hoc/debug";

import ProtectedLayout from "@/layouts/protected";
import UserProfileLayout from "@/layouts/user-profile";
import SemiProtectedLayout from "@/layouts/semi-protected";
import Loading from "@/components/Loading";

import PlaylistPage from "@/pages/playlist";
import DepositPage from "@/pages/payment/deposit";
import VIPPage from "@/pages/payment/vip";
import PersonalInfoPage from "@/pages/profile/personal-info";
import ExplorePage from "@/pages/explore";
import SearchPage from "@/pages/explore/search";
import ChatsPage from "@/pages/chats";
import ChatPage from "@/pages/chat";
import CreatorPage from "@/pages/creator";
import NotificationsPage from "@/pages/notifications";
import FollowedCreators from "@/pages/profile/followed-creators";
import SavedVideos from "@/pages/profile/saved-videos";
import UnlockedVideos from "@/pages/profile/unlocked-videos";
import MembershipPage from "@/pages/profile/membership";
import ErrorPage from "@/pages/error";
import PostRegisterPage from "@/pages/auth/post-register";
import PaymentLayout from "./layouts/payment";
import ExploreLayout from "./layouts/explore";
import { useFirebase } from "@/libs/hooks/firebase";
import useAPI from "@/libs/hooks/api";
import { useMixPanel } from "@/libs/hooks/useMixPanel";
import { creatorStore } from "@/libs/stores/creator.store";
import { AnimatePresence, motion } from "framer-motion";
import StreamsPage from "@/pages/streams";
import CreatorDashboard from "@/pages/creator/dashboard";
import CreatorLayout from "@/layouts/creator";
import CreatorStreamPage from "@/pages/creator/stream";
import CreatorEarningsPage from "@/pages/creator/earnings";
import CreatorChatsPage from "@/pages/creator/chats";
import CreatorChatPage from "@/pages/creator/chat";

const AuthPage = preload(() => import("@/pages/auth"));
const ResetPasswordPage = preload(() => import("@/pages/auth/reset-password"));
const ForgetPasswordPage = preload(
  () => import("@/pages/auth/forget-password"),
);
const TappayDepositCallbackPage = preload(
  () => import("@/pages/callback/tappay/deposit"),
);
const TappayVIPCallbackPage = preload(
  () => import("@/pages/callback/tappay/vip"),
);
const TransactionHistoryPage = preload(
  () => import("@/pages/profile/transaction-history"),
);
const ManageAccountPage = preload(
  () => import("@/pages/profile/manage-account"),
);
const ChangePasswordPage = preload(
  () => import("@/pages/profile/change-password"),
);
const BindEmailPage = preload(() => import("@/pages/profile/bind-email"));
const BindPhonePage = preload(() => import("@/pages/profile/bind-phone"));
const NotificationPage = preload(() => import("@/pages/notification"));
const InstallAppPage = preload(() => import("@/pages/install-app"));

const TestPage2 = preload(() => import("@/pages/test"));
const NotFound = preload(() => import("@/pages/not-found"));

const PageTransition = ({ children, className = "" }) => (
  <AnimatePresence mode="wait">
    <motion.div
      initial={{ opacity: 0.6 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={className}
      key={window.location.pathname}
    >
      {children}
    </motion.div>
  </AnimatePresence>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorPage />}>
      <Route path="/" element={<SemiProtectedLayout />}>
        <Route index element={<PlaylistPage />} />
        <Route path="/videos/:id" element={<PlaylistPage />} />
        <Route
          path="/c/:handle"
          element={
            <PageTransition>
              <CreatorPage />
            </PageTransition>
          }
        />
      </Route>

      <Route path="/auth" element={<AuthLayout />}>
        <Route path="login" element={<AuthPage action="login" />} />
        <Route path="register" element={<AuthPage action="register" />} />
        <Route path="post-register" element={<PostRegisterPage />} />
        <Route path="forget-password" element={<ForgetPasswordPage />} />
        <Route path="reset-password" element={<ResetPasswordPage />} />
      </Route>

      <Route path="/" element={<ProtectedLayout />}>
        <Route path="callback/tappay" element={<TappayDepositCallbackPage />} />
        <Route path="callback/tappay/vip" element={<TappayVIPCallbackPage />} />

        <Route path="/streams/:id?" element={<StreamsPage />} />
        <Route
          path="explore"
          element={
            // <KeepAlive id="explore-page" when={() => true}>
            <PageTransition>
              <ExploreLayout />
            </PageTransition>
            // </KeepAlive>
          }
        >
          <Route index element={<ExplorePage />} />
          <Route path="videos" element={<SearchPage type="videos" />} />
          <Route path="creators" element={<SearchPage type="creators" />} />
        </Route>
        <Route
          path="chats"
          element={
            <KeepAlive id="chats-page" when={() => true}>
              <PageTransition>
                <ChatsPage />
              </PageTransition>
            </KeepAlive>
          }
        />
        <Route
          path="chat/:id"
          element={
            <PageTransition>
              <ChatPage />
            </PageTransition>
          }
        />
        <Route
          path="chat/:creatorId/new"
          element={
            <PageTransition>
              <ChatPage />
            </PageTransition>
          }
        />
        <Route
          path="notifications"
          element={
            <PageTransition>
              <NotificationsPage />
            </PageTransition>
          }
        />
        <Route
          path="notifications/:id"
          element={
            <KeepAlive id="notifications-page" when={() => true}>
              <PageTransition>
                <NotificationPage />
              </PageTransition>
            </KeepAlive>
          }
        />
        <Route path="payment" element={<PaymentLayout />}>
          <Route path="deposit" element={<DepositPage />} />
          <Route path="vip" element={<VIPPage />} />
        </Route>
        <Route path="profile">
          <Route
            path=""
            element={
              <PageTransition className="flex-1">
                <UserProfileLayout />
              </PageTransition>
            }
          >
            <Route index element={<FollowedCreators />} />
            <Route path="followed-creators" element={<FollowedCreators />} />
            <Route path="unlocked-videos" element={<UnlockedVideos />} />
            <Route path="saved-videos" element={<SavedVideos />} />
          </Route>
          <Route path="membership" element={<MembershipPage />} />
          <Route path="personal-info" element={<PersonalInfoPage />} />
          <Route path="manage-account" element={<ManageAccountPage />} />
          <Route path="bind-email" element={<BindEmailPage />} />
          <Route path="bind-phone" element={<BindPhonePage />} />
          <Route path="change-password" element={<ChangePasswordPage />} />

          <Route
            path="transaction-history"
            element={<TransactionHistoryPage />}
          />
        </Route>
      </Route>
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/install-app" element={<InstallAppPage />} />
      <Route path="/creator" element={<CreatorLayout />}>
        <Route index element={<CreatorDashboard />} />
        <Route path="streams" element={<CreatorStreamPage />} />
        <Route path="earnings" element={<CreatorEarningsPage />} />
        <Route path="chats" element={<CreatorChatsPage />} />
        <Route path="chats/:id" element={<CreatorChatPage />} />
      </Route>
      {/*<Route path="/test" element={<TestPage />} />*/}
      <Route path="/test2" element={<TestPage2 />} />
      <Route path="*" element={<NotFound />} />
    </Route>,
  ),
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // staleTime: 1000 * 60 * 5, // 5 minutes
      retry: 3,
    },
  },
});

function App() {
  const { firestore, auth } = useFirebase();
  const api = useAPI();
  useMixPanel(auth);

  useEffect(() => {
    creatorStore.init(api, firestore); // 初始化 Manager
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AliveScope>
        <Suspense fallback={<Loading />}>
          <RouterProvider router={router} />
        </Suspense>
      </AliveScope>
    </QueryClientProvider>
  );
}

export default withContext(withDebug(App));
