import {
  Box,
  Circle,
  Collapse,
  Flex,
  useBoolean,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import { useCallback, useContext, useRef } from "react";
import FAIcon from "../FAIcon";
import Gifts from "@/components/Chat/Gifts";
import Currency from "@/components/Currency";
import StreamContext from "@/context/stream";
import { Link } from "react-router-dom";
import ChatIcon from "@/assets/images/chat.svg?react";
import ChatInput from "./ChatInput";

const InputArea = ({ active, sendMessage, sendGift, onStateChange }) => {
  const { immersive, action, setAction } = useContext(StreamContext);
  const [giftSelecting, setGiftSelecting] = useBoolean(false);
  const textRef = useRef(null);
  const giftsRef = useRef(null);
  const chatRef = useRef(null);

  const {
    isOpen: giftsOpen,
    onClose: closeGiftsUI,
    onToggle: toggleGiftsUI,
  } = useDisclosure({
    onOpen: () => onStateChange?.({ giftsOpen: true }),
    onClose: () => onStateChange?.({ giftsOpen: false }),
  });

  const {
    isOpen: chatOpen,
    onClose: closeChatUI,
    onToggle: toggleChatUI,
  } = useDisclosure({
    onOpen: () => onStateChange?.({ chatOpen: true }),
    onClose: () => onStateChange?.({ chatOpen: false }),
  });

  const closeAll = useCallback(() => {
    if (setAction) {
      setAction(null);
    }
    closeGiftsUI();
    closeChatUI();
  }, [setAction, closeGiftsUI, closeChatUI]);

  useOutsideClick({
    ref: chatRef,
    enabled: active && chatOpen,
    handler: () => {
      console.log("closeChatUI");
      closeAll();
    },
  });

  useOutsideClick({
    ref: giftsRef,
    enabled: !giftSelecting && giftsOpen,
    handler: () => {
      console.log("closeGiftsUI");
      closeGiftsUI();
      closeAll();
    },
  });

  const onGiftSend = useCallback(
    (gift) => {
      sendGift(gift);
      closeGiftsUI();
    },
    [closeGiftsUI, sendGift],
  );

  const onMessageSend = useCallback(
    (message) => {
      sendMessage(message);
      closeChatUI();
    },
    [closeChatUI, sendMessage],
  );

  return (
    <Box
      ref={textRef}
      position="absolute"
      bottom={0}
      hidden={immersive}
      left={0}
      width="100%"
      transition=".2s all"
      zIndex={3}
    >
      <Collapse in={!immersive} animateOpacity={false}>
        <Flex px={4} py={2} justify="flex-start" gap={2}>
          <Circle
            role="button"
            bg="#2D2D2D77"
            size={10}
            onClick={() => {
              toggleChatUI();
              if (setAction) {
                setAction(action ? null : "typing");
              }
            }}
          >
            <ChatIcon color="white" />
          </Circle>
          <Flex gap={2}>
            <Circle
              role="button"
              bg="#2D2D2D77"
              size={10}
              onClick={() => {
                toggleGiftsUI();
                if (setAction) {
                  setAction(action ? null : "gift");
                }
              }}
              transition=".2s all"
            >
              <FAIcon type="gift" variant="regular" color="white" />
            </Circle>
            <Link to="/payment/deposit">
              <Circle role="button" bg="#2D2D2D77" size={10}>
                <Currency size={6} />
              </Circle>
            </Link>
          </Flex>
        </Flex>
      </Collapse>

      <Collapse in={chatOpen} animateOpacity={false} ref={chatRef}>
        <ChatInput
          onSend={onMessageSend}
          onSelect={setGiftSelecting.on}
          onCancel={setGiftSelecting.off}
        />
      </Collapse>

      <Collapse in={giftsOpen} animateOpacity={false} ref={giftsRef}>
        <Gifts
          onSend={onGiftSend}
          onSelect={setGiftSelecting.on}
          onCancel={setGiftSelecting.off}
          bg="#1D1D1D"
        />
      </Collapse>
    </Box>
  );
};

export default InputArea;
