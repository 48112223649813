import { Box, Button, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useCallback, useContext, useMemo, useState } from "react";
import MobileNavigation from "../MobileNavigation";
import useDeviceInfo from "@/libs/hooks/device-info";
import useAPI from "@/libs/hooks/api";
import { useTranslation } from "react-i18next";
import AuthContext from "@/context/auth";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import ShortsPlayer from "@/libs/video/shaka-player";
import VideoActions from "@/components/VideoPost/VideoActions";
import VideoThumbnail from "@/libs/video/components/VideoThumbnail";
import OrderConfirm from "@/components/OrderConfirm";
import Currency from "@/components/Currency";
import VideoOverlay from "@/components/VideoPost/VideoOverlay";
import { runInAction } from "mobx";
import exploreStore from "@/libs/stores/explore.store";
import { playerStore } from "@/libs/stores/player.store";

const VideoPost = ({ id, store = playerStore }) => {
  const [video, setVideo] = useState(() => store.getVideo(id)?.video);
  const { t } = useTranslation();
  const toast = useToast();
  const { width, height, isMobile } = useDeviceInfo();
  const api = useAPI();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const price = video?.price || 0;

  const cta = useMemo(() => {
    if (user)
      return t("videos.actions.unlock_video_with", {
        price,
        currency: t("common.currency"),
      });
    return t("common.cta.install_app");
  }, [price, t, user]);

  const onCTA = useCallback(async () => {
    console.log("onCTA");
    if (user) return onOpen();
  }, [user, onOpen, navigate, toast]);

  // 解鎖視頻功能
  const unlockVideo = useCallback(async () => {
    await api.unlockVideo(id);
    const videoData = await api.getVideo(id);
    console.log("unlockVideo", videoData);
    const v = store.getVideo(id);
    v.video = videoData;
    await v.clearCache();
    await v.load();
    exploreStore.fetchFreshData();
    onClose();

    runInAction(() => {
      setVideo(videoData);
    });
  }, [api, id, video]);

  // 顯示自定義右鍵菜單
  const handleContextMenu = (event) => {
    event.preventDefault();
    console.log("handleContextMenu");

    navigator.clipboard.writeText(id).then(() => {
      toast({
        title: "ID 已複製到剪貼簿！",
        status: "success",
        duration: 2000,
      });
    });
  };

  return (
    <Box
      position="relative"
      height="100%"
      maxW={{ base: "none", md: ((height * 9) / 16) * 0.85 }}
      mx="auto"
      py={{ base: 0, md: 5 }}
      onContextMenu={handleContextMenu} // 右鍵事件
    >
      <Box
        position="relative"
        height="100%"
        aspectRatio={{
          base: width / (height - MobileNavigation.HEIGHT),
          md: 9 / 16,
        }}
        width="100%"
      >
        <Box width="100%" height="100%" overflow="hidden" position="relative">
          <Box position="absolute" height="100%" width="100%">
            <ShortsPlayer videoId={id} store={store} />
          </Box>
        </Box>
        {(store.showThumbnail ||
          id !== store.getActivePlayer()?.id ||
          store.isBuffering) && <VideoThumbnail src={video?.thumbnails} />}
        {store.getActivePlayer()?.id === id && !store.isBuffering && (
          <VideoOverlay store={store}></VideoOverlay>
        )}
        <Box position="absolute" bottom={0} color="white" width="100%">
          <Box p={5}>
            <VideoActions id={id} store={store} />
            {!video?.isUnlocked && (
              <Button
                mt={4}
                width="full"
                variant="themed-gradient"
                onClick={onCTA}
              >
                <Currency size={6} />
                {cta}
                <Currency size={6} />
              </Button>
            )}
          </Box>
        </Box>
        <OrderConfirm
          isOpen={isOpen}
          onClose={onClose}
          onCancel={onClose}
          onConfirm={unlockVideo}
          entry={t("videos.actions.unlock_video")}
          amount={-price}
        />
      </Box>
    </Box>
  );
};

export default observer(VideoPost);
