import {
  Box,
  Center,
  Circle,
  CircularProgress,
  Flex,
  Image,
  Square,
  Text,
  VStack,
} from "@chakra-ui/react";
import Header from "@/components/Header";
import { useTranslation } from "react-i18next";
import notificationPlaceholder from "@/assets/images/notification-placeholder.png";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import FAIcon from "@/components/FAIcon";
import getPublicUrl from "@/libs/get-public-url";
import canonicalDate from "@/libs/canonical-date";
import { useQuery } from "@tanstack/react-query";
import useAPI from "@/libs/hooks/api";

const Notification = ({
  id,
  title,
  target,
  body,
  link,
  action,
  thumbnail,
  updatedAt,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const slug = useMemo(() => {
    switch (true) {
      case target === "system":
      case target === "users":
        return t("notifications.target.system");
      case target === "operation":
        return t("notifications.target.operation");
      case target.startsWith("creator") && target.endsWith("followers"):
        return t("notifications.target.followed_creator");
      default:
        return null;
    }
  }, [t, target]);

  const defaultThumbnail = useMemo(() => {
    let iconVariant,
      iconType,
      bgColor = "secondary.500";
    switch (true) {
      case target === "system":
      case target === "users":
      case target === "operation": {
        bgColor = "#457AE4";
        iconType = "megaphone";
        break;
      }
      case target.startsWith("creator") && target.endsWith("followers"): {
        iconVariant = "solid";
        iconType = "fire";
        bgColor = "#EE3D52";
        break;
      }
      default:
    }
    return (
      <Circle size="4rem" bg={bgColor}>
        <FAIcon variant={iconVariant} type={iconType} fontSize="2xl" />
      </Circle>
    );
  }, [target]);

  const executeAction = useCallback(
    (action) => {
      switch (action.type) {
        case "NAVIGATE_TO_CREATOR_PAGE":
          return navigate(`/creatos/${action.params.id}`);
        default:
          return null;
      }
    },
    [navigate],
  );

  const handleClicked = useCallback(() => {
    switch (true) {
      case !!action:
        return executeAction(action);
      case !!link:
        return link?.startsWith("https://")
          ? window.open(link)
          : navigate(link);
      case !!body:
      default:
        return navigate(`/notifications/${id}`);
    }
  }, [action, body, executeAction, id, link, navigate]);

  return (
    <Flex
      minWidth={{ base: "100vw", md: "100%" }}
      overflow="hidden"
      height={28}
      align="stretch"
    >
      <Flex
        role="button"
        width="100vw"
        onClick={handleClicked}
        bg="#1C1914"
        p={6}
        pb={3}
        gap={3}
        color="white"
        borderBottom="2px"
        borderColor="#F6A01E44"
      >
        <Center position="relative">
          {thumbnail ? (
            <Image
              boxSize="4rem"
              src={getPublicUrl(thumbnail)}
              objectFit="cover"
              rounded="full"
            />
          ) : (
            defaultThumbnail
          )}
        </Center>
        <Box flex={1}>
          <Flex pb={1} gap={2} align="center">
            <Text color="#F6A01E">@{slug}</Text>
          </Flex>

          <Text noOfLines={2}>{title}</Text>
        </Box>
        <Box>
          <Text>{canonicalDate(updatedAt)}</Text>
        </Box>
      </Flex>
    </Flex>
  );
};

const NotificationsPage = () => {
  const { t } = useTranslation();
  const api = useAPI();
  const [displayCount, setDisplayCount] = useState(20);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const {
    data: notifications = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ["notifications"],
    queryFn: () => api.getNotifications(),
    staleTime: 10 * 60 * 1000, // 10 minutes
    cacheTime: 15 * 60 * 1000, // 15 minutes
    retry: 3,
    onError: (err) => console.error("Query error:", err),
    select: (data) => data || [],
  });

  useEffect(() => {
    if (inView && notifications?.length > displayCount) {
      setDisplayCount((prev) => prev + 20);
    }
  }, [inView, notifications?.length, displayCount]);

  return (
    <Box>
      <Header>
        <Text fontSize="2xl" color="white" m="auto">
          {t("page.title.notifications")}
        </Text>
      </Header>
      <Box height="100%" color="white">
        {isLoading && (
          <Center py={6}>
            <CircularProgress color="secondary.500" isIndeterminate />
          </Center>
        )}
        {notifications?.length === 0 && (
          <VStack align="center" height="100%" pt={20}>
            <Square size={36}>
              <Image src={notificationPlaceholder} />
            </Square>
            <Text mt={3}>{t("notifications.placeholder")}</Text>
          </VStack>
        )}
        {notifications?.slice(0, displayCount).map((notification) => (
          <Notification key={notification.id} {...notification} />
        ))}
        <Box ref={ref} h="20px" />
      </Box>
    </Box>
  );
};

export default NotificationsPage;
