import Currency from "../Currency";
import Pill from "../Pill";
import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import firebase from "@/libs/firebase";

const StreamIncome = ({ id }) => {
  const [income, setIncome] = useState(0);

  useEffect(() => {
    if (!id) return;

    const unsubscribe = onSnapshot(
      doc(firebase.firestore, "stream-status", id),
      (doc) => {
        if (doc.exists()) {
          setIncome(doc.data().income || 0);
        }
      },
    );

    return () => unsubscribe();
  }, [id]);

  return (
    <Pill bg="translucent">
      <Currency size={4} mt={1} mr={1} />
      {income}
    </Pill>
  );
};

export default StreamIncome;
