import { Box, Button, Flex, SimpleGrid, Skeleton, Text, useToast } from "@chakra-ui/react";
import MobXCreatorAvatar from "@/components/MobXCreatorAvatar";
import FAIcon from "@/components/FAIcon";
import Header from "@/components/Header";
import { useCreatorVideos } from "@/libs/queries";
import toDisplayNumber from "@/libs/to-display-number";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import usePolyfills from "@/libs/hooks/polyfills";
import LoadMore from "./LoadMore";
import AuthContext from "@/context/auth";
import VideoPreview from "./VideoPreview";
import Playlist from "./Playlist";
import ShareModal from "./ShareModal";
import MemberOnlyWall from "./MemberOnlyWall";
import { observer } from "mobx-react-lite";
import { creatorStore } from "@/libs/stores/creator.store";
import { chatroomStore } from "@/libs/stores/chatroom.store";
import { playerStore } from "@/libs/stores/player.store";

const ActionButton = ({ children, isDisabled, ...props }) => (
  <Button
    variant="themed-outline"
    isDisabled={isDisabled}
    flex={1}
    poistion="relative"
    {...props}
  >
    {children}
    {isDisabled && (
      <Flex
        position="absolute"
        left={0}
        top={0}
        width="100%"
        height="100%"
        bg="rgba(0,0,0,0.5)"
        borderRadius={8}
        align="center"
        justify="center"
      >
        <FAIcon type="lock" variant="regular" fontSize="xl" />
      </Flex>
    )}
  </Button>
);

const CreatorProfile = ({ handle, onBack, onVideoClicked }) => {
  const { t } = useTranslation();
  const { getMaxHeightRepresentation } = usePolyfills();
  const maxHeight = getMaxHeightRepresentation();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [shareData, setShareData] = useState(null);
  const { resolveChatUrl } = chatroomStore;
  const { user } = useContext(AuthContext);
  const toast = useToast();

  const [videos, setVideos] = useState([]);
  const creator = creatorStore.getCreator(handle);
  const {
    data: videosData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: isVideosLoading
  } = useCreatorVideos(creator?.id);
  const isLoading = !creator;

  // 當 videosData 更新時，更新我們的 videos state
  useEffect(() => {
    if (videosData?.pages) {
      const allVideos = videosData.pages.flatMap(page =>
        page.data.map(video => ({
          ...video,
          creatorId: video.creator.id
        }))
      );
      console.log("Updating videos", allVideos.length, allVideos);
      playerStore.setVideos(allVideos);
      setVideos(allVideos);
    }
  }, [videosData]);

  // 只在組件卸載時清理播放佇列
  useEffect(() => {
    console.log("Clearing player store");
    return () => {
      playerStore.clear();
    };
  }, []);

  const [expandIntroduction, setExpandIntroduction] = useState(false);


  const onChatClick = useCallback(async () => {
    const path = await resolveChatUrl(creator.id);
    navigate(path);
  }, [creator, navigate, resolveChatUrl]);

  const clicksVideo = useCallback(
    (index) => () => {
      console.log("Click video", index);
      // Store current scroll position
      const scrollPosition = window.scrollY;
      playerStore.openPlayList(index);
      if (onVideoClicked) onVideoClicked();
      // Restore scroll position after a short delay
      setTimeout(() => {
        window.scrollTo(0, scrollPosition);
      }, 100);
    },
    [onVideoClicked]
  );


  const shareCreator = useCallback(async () => {
    const canShare = typeof navigator?.share === "function";
    const data = {
      title: `來 NightCo 與${creator.displayName}相遇 ♥️`,
      text: creator.introduce,
      url: `https://${window.location.host}/c/@${creator.slug}`
    };
    if (canShare) {
      try {
        await navigator.share(data);
        toast({ title: "已分享!", status: "info" });
      } catch (e) {
        const isAborted = e instanceof DOMException && e.name === "AbortError";
        if (!isAborted) console.error(e);
      }
    } else {
      setShareData(data);
    }
  }, [creator, toast]);

  const loadMoreVideos = useCallback(async () => {
    if (isFetchingNextPage || !hasNextPage) return;

    try {
      await fetchNextPage();
    } catch (error) {
      console.error("Error loading more videos:", error);
      toast({
        title: t("errors.load_videos_failed"),
        status: "error",
        duration: 3000
      });
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage, toast, t]);


  return (
    <Box bg="primary.100" color="white" minH={maxHeight} position="relative">
      <Header gap={3} quickDeposit>
        <Box
          role="button"
          onClick={onBack}
          _active={{ bg: "transparent" }}
          _hover={{ bg: "transparent" }}
        >
          <FAIcon type="chevron-left" fontSize="2xl" />
        </Box>
        <Box>
          <Skeleton isLoaded={!isLoading}>
            <Text>{creator?.displayName || " "}</Text>
            <Text fontSize="xs">{creator?.slug ? `@${creator.slug}` : " "}</Text>
          </Skeleton>
        </Box>
      </Header>
      <Flex py={5} pl={8} pr={12} align="center" textAlign="center" gap={8}>
        <Skeleton isLoaded={!isLoading} borderRadius="full">
          <Box width="80px" height="80px">
            {creator?.id && <MobXCreatorAvatar id={creator.id} size="xl" />}
          </Box>
        </Skeleton>
        <Flex justify="space-between" flex={1}>
          <Box>
            <Skeleton isLoaded={!isLoading} height="36px">
              <Text fontSize="2xl">{toDisplayNumber(creator?.videosTotal || 0)}</Text>
            </Skeleton>
            <Text>{t("creator.total_videos")}</Text>
          </Box>
          <Box>
            <Skeleton isLoaded={!isLoading} height="36px">
              <Text fontSize="2xl">{toDisplayNumber(creator?.followersTotal || 0)}</Text>
            </Skeleton>
            <Text>{t("creator.total_followers")}</Text>
          </Box>
          <Box>
            <Skeleton isLoaded={!isLoading} height="36px">
              <Text fontSize="2xl">{toDisplayNumber(creator?.likersTotal || 0)}</Text>
            </Skeleton>
            <Text>{t("creator.total_likes")}</Text>
          </Box>
        </Flex>
      </Flex>
      <Box px={6} py={3}>
        <Skeleton isLoaded={!isLoading}>
          {creator.introduce ? (
            <>
              <Text
                noOfLines={expandIntroduction ? undefined : 2}
                whiteSpace="pre-wrap"
              >
                {creator.introduce}
              </Text>
              <Text
                onClick={() => setExpandIntroduction(!expandIntroduction)}
                mt={2}
                fontWeight="bold"
              >
                {expandIntroduction ? "收起" : "更多"}
              </Text>
            </>
          ) : (
            <Text height="3em"> </Text>
          )}
        </Skeleton>
      </Box>

      <Flex color="white" gap={3} px={4}>
        <ActionButton
          isLoading={!creator}
          isDisabled={!user}
          onClick={async () => {
            try {
              await creatorStore.toggleFollow(creator.id);
            } catch (error) {
              toast({
                title: t("errors.follow_failed"),
                status: "error",
                duration: 3000
              });
            }
          }}
        >
          {creator?.isFollowed ? (
            <>
              <FAIcon type="check" pr={2} />
              {t("creator.actions.followed")}
            </>
          ) : (
            t("creator.actions.follow")
          )}
        </ActionButton>

        <ActionButton
          isLoading={!creator}
          isDisabled={!user}
          onClick={onChatClick}
        >
          {t("creator.actions.chat")}
        </ActionButton>
        <ActionButton isLoading={!creator} onClick={shareCreator}>
          {t("creator.actions.share")}
        </ActionButton>
      </Flex>

      <SimpleGrid columns={3} spacing={2} p={6}>
        {videos.map((video, index) => (
          <VideoPreview
            key={video.id}
            {...video}
            showPrice={true}
            timestamp={video.updatedAt}
            onClick={clicksVideo(index)}
          />
        ))}
      </SimpleGrid>

      <ShareModal data={shareData} onClose={() => setShareData(null)} />

      {<Playlist
        modal
        autoPlay={false}
        loader={loadMoreVideos}
        done={!hasNextPage}
      />}

      <LoadMore
        loader={loadMoreVideos}
        loading={isFetchingNextPage}
        mt={6}
        done={!creator || !hasNextPage}
      />
      <MemberOnlyWall
        creator={creator}
        loaded={!isVideosLoading && !isFetchingNextPage}
      />
    </Box>
  );
};

export default observer(CreatorProfile);
