import { makeAutoObservable } from "mobx";

class UIStore {
  showHeaderNav: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }

  setShowHeaderNav(show: boolean) {
    this.showHeaderNav = show;
  }

  toggleHeaderNav() {
    this.showHeaderNav = !this.showHeaderNav;
  }
}

export const uiStore = new UIStore();
