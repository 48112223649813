import React, { useEffect, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAgoraClient } from "@/libs/hooks/useAgoraClient";

const AgoraAudienceView = ({ streamId, muted, active }) => {
  const { data: client } = useAgoraClient();
  const videoContainerRef = useRef(null);
  const channelJoined = useRef(false);

  const { data: stream } = useQuery({
    queryKey: ["stream", streamId],
    enabled: !!streamId,
  });

  useEffect(() => {
    if (!client || !stream?.agoraRoomId || !stream?.guestToken || !active) {
      return;
    }

    const joinChannel = async () => {
      if (channelJoined.current) {
        return;
      }
      try {
        const uid = Math.floor(Math.random() * 1000000);
        await client.setClientRole("audience", { level: 2 });
        await client.join(
          "4a314f4d7234472fbcfcfaebbbed79c0",
          stream.agoraRoomId,
          stream.guestToken,
          uid,
        );
        channelJoined.current = true;

        client.on("user-published", async (user, mediaType) => {
          await client.subscribe(user, mediaType);
          if (mediaType === "video") {
            const playerContainer = document.createElement("div");
            playerContainer.id = `player-${user.uid}`;
            playerContainer.style.width = "100%";
            playerContainer.style.height = "100%";
            playerContainer.style.background = "#000";
            playerContainer.style.borderRadius = "8px";
            playerContainer.style.overflow = "hidden";
            videoContainerRef.current?.appendChild(playerContainer);

            user.videoTrack.play(playerContainer);
          }
          if (mediaType === "audio") {
            const audioTrack = user.audioTrack;
            if (audioTrack) {
              audioTrack.play();
            }
            // Update volume after playing
            if (audioTrack && typeof muted !== "undefined") {
              audioTrack.setVolume(muted ? 0 : 100);
            }
          }
        });

        client.on("user-unpublished", (user) => {
          const playerContainer = document.getElementById(`player-${user.uid}`);
          if (playerContainer) {
            playerContainer.remove();
          }
        });
      } catch (error) {
        console.error("Failed to join channel:", error);
      }
    };

    joinChannel().catch(console.error);

    return () => {
      if (!client) return;

      const cleanup = async () => {
        if (channelJoined.current) {
          // Clean up all remote users first
          client.remoteUsers.forEach((user) => {
            const playerContainer = document.getElementById(`player-${user.uid}`);
            if (playerContainer) {
              playerContainer.remove();
            }
          });

          client.removeAllListeners();
          await client.leave().catch(console.error);
          channelJoined.current = false;
        }

        if (videoContainerRef.current) {
          videoContainerRef.current.innerHTML = "";
        }
      };

      cleanup().catch(console.error);
    };
  }, [client, stream?.agoraRoomId, stream?.guestToken, active]);

  // Handle mute state changes separately
  useEffect(() => {
    if (!client) return;
    
    const audioTracks = client.remoteUsers
      .map((user) => user.audioTrack)
      .filter((track) => track);

    audioTracks.forEach((track) => {
      track.setVolume(muted ? 0 : 100);
    });
  }, [muted]);

  return (
    <div ref={videoContainerRef} style={{ width: "100%", height: "100%" }} />
  );
};

export default AgoraAudienceView;
