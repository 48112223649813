import { useEffect, useState } from "react";
import FAIcon from "../FAIcon";
import { useAgoraClient } from "@/libs/hooks/useAgoraClient";
import Pill from "../Pill";

const ViewerCount = ({ id }) => {
  const [count, setCount] = useState(0);
  const { data: client } = useAgoraClient();

  useEffect(() => {
    if (!client) return;

    const handleUserJoined = () => {
      setCount(client.remoteUsers.length);
    };

    const handleUserLeft = () => {
      setCount(client.remoteUsers.length);
    };

    // Set initial count
    setCount(client.remoteUsers.length);

    // Subscribe to user join/leave events
    client.on("user-joined", handleUserJoined);
    client.on("user-left", handleUserLeft);

    return () => {
      client.off("user-joined", handleUserJoined);
      client.off("user-left", handleUserLeft);
    };
  }, [client]);

  return (
    <Pill bg="translucent" color="white">
      <FAIcon type="eye" variant="regular" color="white" mr={1} />
      {count}
    </Pill>
  );
};

export default ViewerCount;
