import React from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  useToast,
  VStack,
} from "@chakra-ui/react";

const StreamSettings = ({
  isOpen,
  onClose,
  title,
  setTitle,
  messagePrice,
  setMessagePrice,
  localTracks,
  isMuted,
  setIsMuted,
  isVideoEnabled,
  setIsVideoEnabled,
}) => {
  const toast = useToast();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>直播設定</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>直播標題</FormLabel>
              <Input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="請輸入直播標題"
              />
            </FormControl>

            <FormControl>
              <FormLabel>訊息收費 (代幣)</FormLabel>
              <NumberInput
                value={messagePrice}
                onChange={(value) => setMessagePrice(Number(value))}
                min={0}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>

            <FormControl>
              <FormLabel>音訊設定</FormLabel>
              <IconButton
                aria-label="靜音"
                icon={
                  <Box
                    as="i"
                    className={`fa-solid fa-${isMuted ? "microphone-slash" : "microphone"}`}
                  />
                }
                onClick={async () => {
                  await localTracks.audioTrack?.setMuted(!isMuted);
                  setIsMuted(!isMuted);
                }}
                isDisabled={!localTracks.audioTrack}
                size="lg"
                colorScheme={isMuted ? "red" : "blue"}
                variant={isMuted ? "solid" : "ghost"}
                width="100%"
              />
            </FormControl>

            <FormControl>
              <FormLabel>視訊設定</FormLabel>
              <Flex gap={2}>
                <IconButton
                  aria-label="關閉視訊"
                  icon={
                    <Box
                      as="i"
                      className={`fa-solid fa-${isVideoEnabled ? "video" : "video-slash"}`}
                    />
                  }
                  onClick={async () => {
                    await localTracks.videoTrack?.setEnabled(!isVideoEnabled);
                    setIsVideoEnabled(!isVideoEnabled);
                  }}
                  isDisabled={!localTracks.videoTrack}
                  size="lg"
                  colorScheme={isVideoEnabled ? "blue" : "red"}
                  variant={isVideoEnabled ? "ghost" : "solid"}
                  flex={1}
                />
                <IconButton
                  aria-label="切換鏡頭"
                  icon={<Box as="i" className="fa-solid fa-camera-rotate" />}
                  onClick={async () => {
                    try {
                      if (!localTracks.videoTrack) return;

                      // Get current device ID
                      const currentDevice = await localTracks.videoTrack
                        .getMediaStreamTrack()
                        .getSettings();

                      // Get all video devices
                      const devices = await AgoraRTC.getCameras();

                      // Find next device
                      const currentIndex = devices.findIndex(
                        (device) => device.deviceId === currentDevice.deviceId,
                      );
                      const nextDevice =
                        devices[(currentIndex + 1) % devices.length];

                      // Create new track with next device
                      const newVideoTrack =
                        await AgoraRTC.createCameraVideoTrack({
                          cameraId: nextDevice.deviceId,
                        });

                      // Replace old track
                      await localTracks.videoTrack.replaceTrack(
                        newVideoTrack.getMediaStreamTrack(),
                      );

                      toast({
                        title: "已切換鏡頭",
                        status: "success",
                        duration: 2000,
                      });
                    } catch (error) {
                      console.error("切換鏡頭失敗:", error);
                      toast({
                        title: "切換鏡頭失敗",
                        description: error.message,
                        status: "error",
                        duration: 3000,
                      });
                    }
                  }}
                  isDisabled={!localTracks.videoTrack}
                  size="lg"
                  colorScheme="blue"
                  variant="ghost"
                />
              </Flex>
            </FormControl>

            <Button
              colorScheme="blue"
              width="100%"
              onClick={() => {
                onClose();
                toast({
                  title: "設定已儲存",
                  status: "success",
                  duration: 3000,
                });
              }}
            >
              儲存設定
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default StreamSettings;
